import React, { useContext, useState } from "react";
import { ParamsContext } from "../../contexts/ParamsProviderContext";
import { Category, SearchParams } from "../../data/types";
import { HStack, Icon, Link, Stack } from "@chakra-ui/react";
import { MdOutlineArrowDropDown } from "react-icons/md";

const SideCatList = ({
  category,
  categories,
}: {
  category: Category;
  categories: Category[];
}) => {
  const { setSearchParams } = useContext(ParamsContext);
  const [showChilds, setShowChilds] = useState<boolean>(false);
  return (
    <>
      <HStack
        justifyContent={"space-between"}
        borderBottomColor={"#b0b0b0"}
        borderBottomWidth={1}
        py={1}
      >
        <Link
          _hover={{ color: "#048414", fontWeight: "semibold" }}
          fontSize={14}
          onClick={() => {
            setSearchParams((prev: SearchParams) => ({
              ...prev,
              catId: category.id,
              catName: category.name,
            }));
          }}
        >
          {category.name}
        </Link>
        <Icon
          as={MdOutlineArrowDropDown}
          w={5}
          h={5}
          onClick={() => setShowChilds(!showChilds)}
          cursor={"pointer"}
        />
      </HStack>
      {showChilds && (
        <Stack px={3}>
          {categories.map(
            (cat: Category, index: number) =>
              cat.parentCategoryId === category.id && (
                <Link
                  key={index}
                  _hover={{ color: "#ebbd20", fontWeight: "semibold" }}
                  fontSize={14}
                  onClick={() => {
                    setSearchParams((prev: SearchParams) => ({
                      ...prev,
                      catId: cat.id,
                      catName: cat.name,
                    }));
                  }}
                >
                  {cat.name}
                </Link>
              )
          )}
        </Stack>
      )}
    </>
  );
};

export default SideCatList;
