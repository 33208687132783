import {
  Alert,
  AlertIcon,
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useContext } from "react";
import { WishlistContext } from "../contexts/WishlistProviderContext";
import { LangContext } from "../contexts/LanguageProviderContext";
import { bodyPadding } from "../assets/global";
import { FormattedMessage } from "react-intl";
import { ItemProps } from "../data/types";
import RemoveFromWishList from "../components/Wishlist/RemoveFromWishList";
import RemoveAllFromWishList from "../components/Wishlist/RemoveAllFromWishList";
import { useNavigate } from "react-router-dom";

const Wishlist = () => {
  const { wishlistItems } = useContext(WishlistContext);
  const { direction } = useContext(LangContext);
  const nav = useNavigate();

  return (
    <Box px={bodyPadding} py={10}>
      <Stack dir={direction}>
        {wishlistItems.length === 0 && (
          <Alert status="warning" borderRadius={6}>
            <AlertIcon />
            <Text fontSize={17}>
              <FormattedMessage id="emptyListText" />
              &nbsp;
              <Link
                onClick={() => nav(`/shopping-items`)}
                textDecoration={"underline !important"}
              >
                <FormattedMessage id="emptyListLink" />
              </Link>
            </Text>
          </Alert>
        )}
        {wishlistItems.length > 0 && (
          <Stack gap={7}>
            <Heading as="h2" size="lg">
              <FormattedMessage id="wishlist" />
            </Heading>
            {wishlistItems.map((item: ItemProps, index: number) => (
              <HStack
                key={index}
                px={1}
                justifyContent={{ base: "flex-start", sm: "space-between" }}
                wrap={"wrap"}
                gap={5}
              >
                <Link
                  onClick={() => {
                    nav(`/shopping-items/${item.id}`);
                  }}
                >
                  <Image
                    src={`https://srv14.optimgov.com/images/${item.image}`}
                    alt={`Image of ${item.name}`}
                    boxSize={"70px"}
                  />
                </Link>
                <Text>{item.categoryName}</Text>
                <Text>{item.name}</Text>
                <RemoveFromWishList item={item} />
                <Divider />
              </HStack>
            ))}
            <Flex justifyContent={"flex-end"}>
              <RemoveAllFromWishList />
            </Flex>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default Wishlist;
