import { Link } from "@chakra-ui/react";
import { useContext } from "react";
import { GoHeart } from "react-icons/go";
import { WishlistContext } from "../../contexts/WishlistProviderContext";
import CountBadge from "../common/CountBadge";
import { useNavigate } from "react-router-dom";

const WishListBadge = () => {
  const { wishlistItems } = useContext(WishlistContext);
  const nav = useNavigate();
  return (
    <CountBadge count={wishlistItems.length}>
      <Link fontSize={20} onClick={() => nav("/wishlist")}>
        <GoHeart />
      </Link>
    </CountBadge>
  );
};

export default WishListBadge;
