import {
  Flex,
  HStack,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { useContext } from "react";
import { FaCaretDown } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import { bodyPadding } from "../../assets/global";
import { LangContext } from "../../contexts/LanguageProviderContext";

const LocaleSwitcher = () => {
  return (
    <Flex
      backgroundColor={"white"}
      justifyContent={"space-between"}
      alignItems={"center"}
      py={"2px"}
      px={bodyPadding}
    >
      <Text color={"#5e6c75"} fontSize={15}>
        <FormattedMessage id="LocalSwitcherTitle" />
      </Text>
      <LangMenu />
    </Flex>
  );
};

export default LocaleSwitcher;

const LangMenu = () => {
  const { locale, changeLocale } = useContext(LangContext);

  const menuButton = {
    flag: locale === "ar-ma" ? "/images/flags/ma.svg" : "/images/flags/fr.svg",
    text:
      locale === "ar-ma" ? (
        <FormattedMessage id="arabic" />
      ) : (
        <FormattedMessage id="france" />
      ),
  };

  const menuItem = {
    flag: locale === "ar-ma" ? "/images/flags/fr.svg" : "/images/flags/ma.svg",
    text:
      locale === "ar-ma" ? (
        <FormattedMessage id="france" />
      ) : (
        <FormattedMessage id="arabic" />
      ),
  };

  const handleSelectChange = () => {
    if (locale === "ar-ma") changeLocale("fr-ca");
    else changeLocale("ar-ma");
  };

  return (
    <Menu isLazy>
      <MenuButton>
        <HStack>
          <Image alt="flag" src={menuButton.flag} boxSize={6} />
          <Text color={"#5e6c75"} fontSize={15}>
            {menuButton.text}
          </Text>
          <Icon as={FaCaretDown} color={"#5e6c75"} />
        </HStack>
      </MenuButton>
      <MenuList>
        <MenuItem onClick={handleSelectChange}>
          <HStack px={2}>
            <Image alt="flag" src={menuItem.flag} boxSize={6} />
            <Text color={"#5e6c75"}>{menuItem.text}</Text>
          </HStack>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
