import { Flex, HStack, Icon, Link, Show, Stack, Text } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { bodyPadding } from "../assets/global";
import Evaluation from "../components/ShoppingItems/Evaluation";
import ItemsGrid from "../components/ShoppingItems/ItemsGrid";
import PriceRangeSlider from "../components/ShoppingItems/PriceRangeSlider";
import { Category, SearchParams } from "../data/types";
import { postRequest } from "../utils/db";
import { ParamsContext } from "../contexts/ParamsProviderContext";
import { MdOutlineArrowDropDown } from "react-icons/md";
import SideCatList from "../components/ShoppingItems/SideCatList";

const ShoppingItems = () => {
  const [categories, setCategories] = useState([]);
  const { setSearchParams } = useContext(ParamsContext);

  const fetchData = async () => {
    const data = await postRequest("/api/categories/getCats", {});
    if (!data.error) {
      setCategories(data.content);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Flex px={bodyPadding} gap={10} py={6}>
      <Show above="lg">
        <Stack w={250} gap={5}>
          <Stack>
            <Text fontSize={19} fontWeight={"semibold"}>
              <FormattedMessage id="filterCategories" />
            </Text>
            <Link
              _hover={{ color: "#048414", fontWeight: "semibold" }}
              fontSize={14}
              borderBottomColor={"#b0b0b0"}
              borderBottomWidth={1}
              py={1}
              // onClick={() => {
              //   setSearchParams((prev: SearchParams) => ({
              //     ...prev,
              //     catId: null,
              //     catName: "",
              //   }));
              // }}
              onClick={() =>
                setSearchParams({
                  catId: null,
                  min_price: 0,
                  max_price: 1500,
                  name: "",
                  display: 10,
                  evaluation: null,
                  orderBy: "",
                  page: 1,
                })
              }
            >
              <FormattedMessage id="allCategories" />
            </Link>
            {categories.map(
              (category: Category, index: number) =>
                category.parentCategoryId === 0 && (
                  <SideCatList
                    key={index}
                    categories={categories}
                    category={category}
                  />
                )
            )}
          </Stack>
          <Stack>
            <Text fontSize={19} fontWeight={"semibold"}>
              <FormattedMessage id="filterPrice" />
            </Text>
            <PriceRangeSlider />
          </Stack>
          <Stack>
            <Text fontSize={19} fontWeight={"semibold"}>
              <FormattedMessage id="filterEvaluation" />
            </Text>
            <Evaluation />
          </Stack>
        </Stack>
      </Show>
      <ItemsGrid />
    </Flex>
  );
};

export default ShoppingItems;
