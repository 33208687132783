import { Flex, Text } from "@chakra-ui/react";
import { CardProps } from "../../data/types";
import { LuTruck } from "react-icons/lu";
import { FaBox } from "react-icons/fa6";
import { RiRefund2Line } from "react-icons/ri";
import { FaMoneyBills } from "react-icons/fa6";
import { useContext } from "react";
import { LangContext } from "../../contexts/LanguageProviderContext";
import messages from "../../i18n/messages";

const ServicesCards = () => {
  const { locale } = useContext(LangContext);
  return (
    <Flex justifyContent={"space-evenly"} pb={19} flexWrap={"wrap"} gap={5}>
      <ServiceCard icon={LuTruck} text={messages[locale].helpCenterCard1} />
      <ServiceCard icon={FaBox} text={messages[locale].helpCenterCard2} />
      <ServiceCard
        icon={RiRefund2Line}
        text={messages[locale].helpCenterCard3}
      />
      <ServiceCard
        icon={FaMoneyBills}
        text={messages[locale].helpCenterCard4}
      />
    </Flex>
  );
};

export default ServicesCards;

const ServiceCard = ({ icon, text }: CardProps) => {
  const Icon = icon;
  return (
    <Flex
      flexDir={"column"}
      backgroundColor={"rgb(240,243,242)"}
      w={250}
      py={10}
      px={5}
      borderRadius={9}
    >
      <Text fontSize={50} px={3} pb={3} color={"rgb(91,108,117)"}>
        <Icon />
      </Text>
      <Text fontWeight={"semibold"} fontSize={16}>
        {text}
      </Text>
    </Flex>
  );
};
