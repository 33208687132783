import { Box, ChakraProvider, extendTheme } from "@chakra-ui/react";
import { ReactNode, useContext } from "react";
import { LangContext } from "../contexts/LanguageProviderContext";

const breakpoints = {
  base: "0em",
  extra_sm: "370px",
  sm: "30em", //480px
  md: "48em", //768px
  md2: "820px",
  lg: "62em", //992px
  xl: "80em", //1280px
  "2xl": "96em", //1536px
};

const fonts = {
  heading: `'Quicksand Variable', sans-serif`,
  body: `'Quicksand Variable', sans-serif`,
};

const theme = extendTheme({
  breakpoints,
  fonts,
});

const ChakraUIProvider = ({ children }: { children: ReactNode }) => {
  const { direction } = useContext(LangContext);
  return (
    <ChakraProvider theme={theme}>
      <Box fontSize={"14px"} dir={direction}>
        {children}
      </Box>
    </ChakraProvider>
  );
};

export default ChakraUIProvider;
