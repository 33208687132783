import { ReactNode, useContext } from "react";
import { LangContext } from "../../../contexts/LanguageProviderContext";
import { FaLongArrowAltRight, FaLongArrowAltLeft } from "react-icons/fa";
import { Button, Link } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const ShopNowButton = ({ children }: { children: ReactNode }) => {
  const { locale } = useContext(LangContext);
  const nav = useNavigate();
  const ButtonIcon =
    locale === "ar-ma" ? FaLongArrowAltLeft : FaLongArrowAltRight;

  return (
    <Link onClick={() => nav("/shopping-items")}>
      <Button
        rightIcon={<ButtonIcon />}
        color={"#ffffff"}
        backgroundColor={"#000000"}
        variant="solid"
        w={"fit-content"}
        _hover={{ bg: "#3b3636" }}
      >
        {children}
      </Button>
    </Link>
  );
};

export default ShopNowButton;
