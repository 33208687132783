import {
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext } from "react";
import { IoMdClose } from "react-icons/io";
import { AuthContext } from "../../contexts/AuthProviderContext";
import { FormattedMessage } from "react-intl";
import AddressForm from "./AddressForm";

const AddNewAddressModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { direction } = useContext(AuthContext);

  return (
    <>
      <Button
        onClick={onOpen}
        backgroundColor={"white"}
        borderColor={"black"}
        borderWidth={1}
        _hover={{ bg: "#01114d", color: "white" }}
      >
        <FormattedMessage id="addNewModel" />
      </Button>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent dir={direction}>
          <ModalHeader dir={direction}>
            <Flex justifyContent={"space-between"} alignItems={"center"}>
              <Text>
                <FormattedMessage id="modelTitle" />
              </Text>
              <Link onClick={onClose} color={"#000000"}>
                <IoMdClose />
              </Link>
            </Flex>
          </ModalHeader>
          <ModalBody>
            <AddressForm onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddNewAddressModal;
