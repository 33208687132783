import {
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FormEvent, useContext } from "react";
import { IoMdClose } from "react-icons/io";
import { IoCheckmarkCircle } from "react-icons/io5";
import { FormattedMessage } from "react-intl";
import { AuthContext } from "../../../contexts/AuthProviderContext";
import { LangContext } from "../../../contexts/LanguageProviderContext";
import messages from "../../../i18n/messages";
import RemoveAllItemsBtn from "./RemoveAllItemsBtn";
import SignInForm from "../../Account/SignInForm";
import { useNavigate } from "react-router-dom";

const ExecuteCart = ({
  onClose,
  session,
}: {
  onClose: () => void;
  session: any;
}) => {
  const nav = useNavigate();

  return (
    <Flex w={"100%"} justifyContent={"flex-end"} gap={2}>
      <RemoveAllItemsBtn />
      {!session && <ExecuteCartLoginModal closeDrawer={onClose} />}
      {session && (
        <Link>
          <Button
            colorScheme="green"
            size="sm"
            leftIcon={<IoCheckmarkCircle />}
            type={"submit"}
            onClick={() => {
              if (onClose) onClose();
              nav("/shopping-cart");
            }}
          >
            <FormattedMessage id="execute" />
          </Button>
        </Link>
      )}
    </Flex>
  );
};

export default ExecuteCart;

const ExecuteCartLoginModal = ({
  closeDrawer,
}: {
  closeDrawer: () => void;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { locale, direction } = useContext(LangContext);
  const { signIn } = useContext(AuthContext);
  const nav = useNavigate();
  const toast = useToast();

  const handleFormAction = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data = await signIn(formData);
    if (data?.error) {
      toast({
        description: messages[locale].signInError,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    } else {
      nav(`/shopping-cart`);
      closeDrawer();
    }
  };

  return (
    <>
      <Button
        colorScheme="green"
        size="sm"
        leftIcon={<IoCheckmarkCircle />}
        type={"submit"}
        onClick={onOpen}
      >
        <FormattedMessage id="execute" />
      </Button>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent dir={direction}>
          <ModalHeader dir={direction}>
            <Flex justifyContent={"space-between"} alignItems={"center"}>
              <Text>
                <FormattedMessage id="signInButton" />
              </Text>
              <Link onClick={onClose} color={"#000000"}>
                <IoMdClose />
              </Link>
            </Flex>
          </ModalHeader>
          <ModalBody>
            <SignInForm handleSubmit={handleFormAction} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
