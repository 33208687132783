import { Stack, Heading, Divider, Text } from "@chakra-ui/react";
import MyAccountLayout from "../components/MyAccountLayout";
import { FormattedMessage } from "react-intl";
import DeleteAccount from "../components/AccountSettings/DeleteAccount";
import ResetPasswordForm from "../components/AccountSettings/ResetPasswordForm";
import UpdateUserData from "../components/AccountSettings/UpdateUserData";

const AccountSettings = () => {
  return (
    <MyAccountLayout>
      <Stack w={900} p={3}>
        <Stack gap={4} w={"fit-content"}>
          <Heading as="h2" size="lg">
            <FormattedMessage id="accountSettings" />
          </Heading>
          <Text fontSize={18} fontWeight={"semibold"}>
            <FormattedMessage id="detailsTitle" />
          </Text>
          <UpdateUserData />
        </Stack>
        <Divider my={8} />
        <Text fontSize={18} fontWeight={"semibold"}>
          <FormattedMessage id="password" />
        </Text>
        <ResetPasswordForm />
        <Divider my={8} />
        <Stack gap={4}>
          <Text fontSize={18} fontWeight={"semibold"}>
            <FormattedMessage id="deleteAccountTitle" />
          </Text>
          <Text fontWeight={"semibold"} color={"#818181"}>
            <FormattedMessage id="deleteAccQuestion" />
          </Text>
          <Text fontWeight={"semibold"} color={"#818181"}>
            <FormattedMessage id="deleteAccNote2" />
          </Text>
          <DeleteAccount />
        </Stack>
      </Stack>
    </MyAccountLayout>
  );
};

export default AccountSettings;
