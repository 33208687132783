import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Link,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { AiOutlineMenuFold } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { Category } from "../../../data/types";
import { useContext } from "react";
import { LangContext } from "../../../contexts/LanguageProviderContext";
import SearchInput from "../SearchInput";
import Menu from ".";
import { FormattedMessage } from "react-intl";

const SideMenuDrawer = ({
  session,
  categories,
}: {
  session: any;
  categories: Category[];
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { locale, direction } = useContext(LangContext);

  return (
    <>
      <Box
        fontSize={25}
        onClick={onOpen}
        color={"#f1c232"}
        cursor={"pointer"}
        transform={
          locale === "ar-ma" ? "translateX(10px)" : "translateX(-10px)"
        }
      >
        <AiOutlineMenuFold />
      </Box>
      <Drawer
        onClose={onClose}
        isOpen={isOpen}
        placement={"left"}
        size={{ base: "full", md: "sm" }}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader color={"green"} dir={direction}>
            <Flex justifyContent={"space-between"} alignItems={"center"}>
              <Text>
                <FormattedMessage id="appTitle" />
              </Text>
              <Link onClick={onClose} color={"#000000"}>
                <IoMdClose />
              </Link>
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <Stack gap={4} dir={direction}>
              <SearchInput menuLocation={"side"} closeDrawer={onClose} />
              <Menu
                session={session}
                menuLocation={"side"}
                closeDrawer={onClose}
                categories={categories}
              />
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default SideMenuDrawer;
