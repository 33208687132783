import { useEffect, useState } from "react";
import { getRequest } from "../../utils/db";
import { SimpleGrid } from "@chakra-ui/react";
import { ItemProps } from "../../data/types";
import ItemCard from "../ShoppingItems/ItemCard";
import ItemCardContainer from "../ShoppingItems/ItemCardContainer";

const RelatedArticalsContainer = ({ catId }: { catId: number }) => {
  const [products, setProducts] = useState([]);

  const fetchData = async () => {
    const data = await getRequest(
      `/api/products/getProductsByParam?cat_Id=${catId}`
    );
    if (!data.error) {
      setProducts(data);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <SimpleGrid columns={{ base: 1, sm: 2, lg: 4 }} spacing={6} pb={10}>
      {products.map(
        (product: ItemProps, index: number) =>
          index < 4 && (
            <ItemCardContainer key={index}>
              <ItemCard item={product} />
            </ItemCardContainer>
          )
      )}
    </SimpleGrid>
  );
};

export default RelatedArticalsContainer;
