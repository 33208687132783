import { Button, Flex, Text, useToast } from "@chakra-ui/react";
import { AddressFormInitVals, SearchParams } from "../../../data/types";
import { FormEvent, useContext, useEffect, useState } from "react";
import { LangContext } from "../../../contexts/LanguageProviderContext";
import { AuthContext } from "../../../contexts/AuthProviderContext";
import { handleAddAddress, handleUpdateAddress } from "./action";
import messages from "../../../i18n/messages";
import { FormattedMessage } from "react-intl";
import AddressFormInput from "./AddressFormInput";
import { useNavigate } from "react-router-dom";
import { ParamsContext } from "../../../contexts/ParamsProviderContext";

const AddressForm = ({
  initialValues,
  onClose,
}: {
  initialValues?: AddressFormInitVals;
  onClose?: () => void;
}) => {
  const toast = useToast();
  const { direction, locale } = useContext(LangContext);
  const { getSession } = useContext(AuthContext);
  const { setSearchParams } = useContext(ParamsContext);

  const [session, setSession] = useState();

  const fetchSession = async () => {
    const data = await getSession();
    if (data) setSession(data);
  };

  useEffect(() => {
    fetchSession();
  }, []);

  const handleFormAction = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const dataItem = {
      firstName: formData.get("firstName"),
      lastName: formData.get("lastName"),
      firstAddress: formData.get("firstAddress"),
      secondAddress: formData.get("secondAddress"),
      city: formData.get("city"),
      phone: formData.get("phone"),
      postNum: formData.get("postNum"),
      work: formData.get("work"),
      // setAsDefault: formData.get("setAsDefault") === "on" ? true : false,
    };

    if (
      dataItem.firstName === "" ||
      dataItem.lastName === "" ||
      dataItem.firstAddress === "" ||
      dataItem.secondAddress === "" ||
      dataItem.phone === "" ||
      dataItem.postNum === "" ||
      dataItem.city === ""
    ) {
      toast({
        description: `Please make sure that all fields are added`,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    if (initialValues) {
      const data = await handleUpdateAddress(
        formData,
        initialValues.id!,
        session
      );
      if (data!.error) {
        toast({
          description: messages[locale].errorUpdating,
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      } else {
        toast({
          description: messages[locale].successUpdating,
          status: "success",
          duration: 4000,
          isClosable: true,
        });
        setSearchParams((prev: SearchParams) => ({
          ...prev,
          updateAddressKey: 1,
        }));
      }
    } else {
      const data = await handleAddAddress(formData, session);
      if (data!.error) {
        toast({
          description: messages[locale].errorAdding,
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      } else {
        toast({
          description: messages[locale].successAdding,
          status: "success",
          duration: 4000,
          isClosable: true,
        });
        setSearchParams((prev: SearchParams) => ({
          ...prev,
          updateAddressKey: 2,
        }));
      }
    }
    if (onClose) onClose();
  };

  return (
    <form onSubmit={handleFormAction} dir={direction}>
      <Text fontSize={13} pb={3} fontWeight={"semibold"} color={"#797b7e"}>
        <FormattedMessage id="modelDesc" />
      </Text>
      <AddressFormInput
        name={"firstName"}
        placeholder={messages[locale].firstName}
        value={initialValues?.firstName ?? ""}
      />
      <AddressFormInput
        name={"lastName"}
        placeholder={messages[locale].lastName}
        value={initialValues?.lastName ?? ""}
      />
      <AddressFormInput
        name={"firstAddress"}
        placeholder={messages[locale].firstAddress}
        value={initialValues?.firstAddress ?? ""}
      />
      <AddressFormInput
        name={"secondAddress"}
        placeholder={messages[locale].secondAddress}
        value={initialValues?.secondAddress ?? ""}
      />
      <AddressFormInput
        name={"city"}
        placeholder={messages[locale].city}
        value={initialValues?.city ?? ""}
      />
      <AddressFormInput
        name={"phone"}
        placeholder={messages[locale].phone}
        value={initialValues?.phone ?? ""}
      />
      <AddressFormInput
        name={"postNum"}
        placeholder={messages[locale].postCode}
        value={initialValues?.postNum ?? ""}
      />
      <AddressFormInput
        name={"work"}
        placeholder={messages[locale].businessName}
        value={initialValues?.work ?? ""}
      />
      {/* <AddressFormCheckbox
        text={t("setAsDefault")}
        value={initialValues?.setAsDefault ?? false}
      /> */}
      <Flex justifyContent={"flex-end"} p={3}>
        <Button
          type="submit"
          backgroundColor={"#01114d"}
          color={"white"}
          borderColor={"#01114d"}
          borderWidth={1}
          _hover={{ bg: "white", color: "#01114d" }}
        >
          <FormattedMessage id="saveAddress" />
        </Button>
      </Flex>
    </form>
  );
};

export default AddressForm;
