import {
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
  Flex,
} from "@chakra-ui/react";
import { AddressFormInitVals } from "../../data/types";
import { useContext } from "react";
import { LangContext } from "../../contexts/LanguageProviderContext";
import { FormattedMessage } from "react-intl";
import { IoMdClose } from "react-icons/io";
import AddressForm from "./AddressForm";

const ModifyAddressModal = ({
  initialValues,
}: {
  initialValues: AddressFormInitVals;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { direction } = useContext(LangContext);
  return (
    <>
      <Link onClick={onOpen} w={"fit-content"} _hover={{ color: "#6aa84f" }}>
        <FormattedMessage id="modify" />
      </Link>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent dir={direction}>
          <ModalHeader dir={direction}>
            <Flex justifyContent={"space-between"} alignItems={"center"}>
              <Text>
                <FormattedMessage id="modifyModelTitle" />
              </Text>
              <Link onClick={onClose} color={"#000000"}>
                <IoMdClose />
              </Link>
            </Flex>
          </ModalHeader>
          <ModalBody>
            <AddressForm onClose={onClose} initialValues={initialValues} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModifyAddressModal;
