import {
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Text,
  Flex,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { LangContext } from "../../contexts/LanguageProviderContext";
import messages from "../../i18n/messages";
import { ParamsContext } from "../../contexts/ParamsProviderContext";
import { SearchParams } from "../../data/types";

const PriceRangeSlider = () => {
  const { locale } = useContext(LangContext);
  const { searchParams, setSearchParams } = useContext(ParamsContext);
  return (
    <>
      <RangeSlider
        // aria-label={["min", "max"]}
        defaultValue={[searchParams.min_price, searchParams.max_price]}
        min={0}
        max={1500}
        onChangeEnd={(val) => {
          setSearchParams((prev: SearchParams) => ({
            ...prev,
            min_price: val[0],
            max_price: val[1],
          }));
        }}
      >
        <RangeSliderTrack>
          <RangeSliderFilledTrack />
        </RangeSliderTrack>
        <RangeSliderThumb index={0} />
        <RangeSliderThumb index={1} />
      </RangeSlider>
      <Flex gap={2}>
        <Text>
          <FormattedMessage id="filterPrice" />
        </Text>
        <Text>
          {` ${searchParams.min_price} ${messages[locale].currency} - ${searchParams.max_price} ${messages[locale].currency}`}
        </Text>
      </Flex>
    </>
  );
};

export default PriceRangeSlider;
