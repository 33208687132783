import { HStack } from "@chakra-ui/react";
import { ChangeEvent, useContext } from "react";
import { ParamsContext } from "../../contexts/ParamsProviderContext";
import { SearchParams } from "../../data/types";
import { FormattedMessage, FormattedNumber } from "react-intl";

const ItemsDisplayAndOrder = () => {
  const { searchParams, setSearchParams } = useContext(ParamsContext);

  const initOrder =
    searchParams?.priceOrder && searchParams?.priceOrder === "asc"
      ? "priceLower"
      : searchParams?.priceOrder && searchParams?.priceOrder === "desc"
      ? "priceHigher"
      : searchParams?.dateOrder && searchParams?.dateOrder === "desc"
      ? "sortByDate"
      : "featured";

  const changeDisplay = (event: ChangeEvent<HTMLSelectElement>) => {
    setSearchParams((prev: SearchParams) => ({
      ...prev,
      display: event.target.value,
    }));
  };

  const changeOrder = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    if (value === "priceLower")
      setSearchParams((prev: SearchParams) => ({
        ...prev,
        dateOrder: "",
        priceOrder: "asc",
      }));
    else if (value === "priceHigher")
      setSearchParams((prev: SearchParams) => ({
        ...prev,
        dateOrder: "",
        priceOrder: "desc",
      }));
    else if (value === "date")
      setSearchParams((prev: SearchParams) => ({
        ...prev,
        dateOrder: "desc",
        priceOrder: "asc",
      }));
    else
      setSearchParams((prev: SearchParams) => ({
        ...prev,
        dateOrder: "",
        priceOrder: "",
      }));
  };

  return (
    <HStack wrap={"wrap"}>
      <select
        style={selectStyle}
        onChange={changeDisplay}
        defaultValue={searchParams.display ?? 10}
      >
        <option value={50}>
          <FormattedMessage id="display" /> &nbsp;
          <FormattedNumber value={50} />
        </option>
        <option value={30}>
          <FormattedMessage id="display" /> &nbsp;
          <FormattedNumber value={30} />
        </option>
        <option value={20}>
          <FormattedMessage id="display" /> &nbsp;
          <FormattedNumber value={20} />
        </option>
        <option value={10}>
          <FormattedMessage id="display" /> &nbsp;
          <FormattedNumber value={10} />
        </option>
      </select>
      <select
        style={selectStyle}
        onChange={changeOrder}
        defaultValue={initOrder}
      >
        <option value={"featured"}>
          <FormattedMessage id="sortByFeatured" />
        </option>
        <option value={"priceLower"}>
          <FormattedMessage id="sortByPriceLower" />
        </option>
        <option value={"priceHigher"}>
          <FormattedMessage id="sortByPriceHigher" />
        </option>
        <option value={"date"}>
          <FormattedMessage id="sortByDate" />
        </option>
      </select>
    </HStack>
  );
};

export default ItemsDisplayAndOrder;

const selectStyle = {
  padding: "8px 12px",
  backgroundColor: "#ffffff",
  border: "1px solid #bcbcbc",
  borderRadius: 4,
};
