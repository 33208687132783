import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  HStack,
  Icon,
  Link,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { IoMdClose } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";
import { ItemProps } from "../../data/types";
import { useContext, useRef } from "react";
import { WishlistContext } from "../../contexts/WishlistProviderContext";
import { LangContext } from "../../contexts/LanguageProviderContext";
import { FormattedMessage } from "react-intl";

const RemoveFromWishList = ({ item }: { item: ItemProps }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { removeFromWishlist } = useContext(WishlistContext);
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const { direction } = useContext(LangContext);

  return (
    <>
      <HStack
        fontWeight={"semibold"}
        fontSize={14}
        gap={1}
        cursor={"pointer"}
        onClick={onOpen}
      >
        <Icon as={MdDeleteForever} color={"#6aa84f"} fontSize={15} />
        <Text color={"#999999"}>
          <FormattedMessage id="remove" />
        </Text>
      </HStack>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader dir={direction}>
            <Flex justifyContent={"space-between"} alignItems={"center"}>
              <Text>
                <FormattedMessage id="confirmRemovingItemTitle" />
              </Text>
              <Link onClick={onClose} color={"#000000"}>
                <IoMdClose />
              </Link>
            </Flex>
          </AlertDialogHeader>
          <AlertDialogBody>
            <FormattedMessage id="removeItemConf" />
          </AlertDialogBody>
          <AlertDialogFooter dir={direction}>
            <HStack gap={2}>
              <Button ref={cancelRef} onClick={onClose}>
                <FormattedMessage id="no" />
              </Button>
              <Button
                colorScheme="red"
                ml={3}
                onClick={() => {
                  removeFromWishlist(item);
                  onClose();
                }}
              >
                <FormattedMessage id="yes" />
              </Button>
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default RemoveFromWishList;
