import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Flex,
  ModalBody,
  Text,
  useDisclosure,
  Link,
} from "@chakra-ui/react";
import { useState, useContext, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { FormattedMessage } from "react-intl";
import { AuthContext } from "../../../../contexts/AuthProviderContext";
import AddReviewModal from "./AddReviewModal";
import RatingSelector from "./RatingSelector";
import { LangContext } from "../../../../contexts/LanguageProviderContext";
import { ItemProps } from "../../../../data/types";

const AddNewReview = ({ product }: { product: ItemProps }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [session, setSession] = useState<any>();
  const { getSession } = useContext(AuthContext);
  const { locale, direction } = useContext(LangContext);

  const fetchSession = async () => {
    const data = await getSession();
    if (data) setSession(data);
  };

  useEffect(() => {
    fetchSession();
  }, []);

  return (
    <>
      {!session && (
        <AddReviewModal openAddReview={() => onOpen()} session={session} />
      )}
      {session && (
        <>
          <Button
            onClick={onOpen}
            backgroundColor={"#000"}
            textColor={"#ffffff"}
            _hover={{ bg: "#444444" }}
          >
            <FormattedMessage id="addYourReview" />
          </Button>
          <Modal onClose={onClose} isOpen={isOpen} isCentered>
            <ModalOverlay />
            <ModalContent dir={direction}>
              <ModalHeader dir={direction}>
                <Flex justifyContent={"space-between"} alignItems={"center"}>
                  <Text>
                    <FormattedMessage id="addYourReview" />
                  </Text>
                  <Link onClick={onClose} color={"#000000"}>
                    <IoMdClose />
                  </Link>
                </Flex>
              </ModalHeader>
              <ModalBody>
                <RatingSelector product={product} />
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
};

export default AddNewReview;
