import {
  Box,
  Divider,
  Flex,
  HStack,
  Show,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FormEvent, ReactNode, useContext, useEffect } from "react";
import { MdLogout } from "react-icons/md";
import { AuthContext } from "../../contexts/AuthProviderContext";
import { useNavigate } from "react-router-dom";
import { bodyPadding } from "../../assets/global";
import SideLink from "./SideLink";
import { LangContext } from "../../contexts/LanguageProviderContext";
import messages from "../../i18n/messages";
import { FormattedMessage } from "react-intl";

const MyAccountLayout = ({ children }: { children: ReactNode }) => {
  const { getSession, signOut } = useContext(AuthContext);
  const { locale } = useContext(LangContext);
  const nav = useNavigate();
  const checkSession = async () => {
    const sess = await getSession();
    if (sess === null) {
      await signOut();
      nav("/");
    }
  };
  useEffect(() => {
    checkSession();
  }, []);
  const handleSignOut = async (e: FormEvent<HTMLElement>) => {
    e.preventDefault();
    await signOut();
    nav("/");
  };

  return (
    <Flex px={bodyPadding} gap={10}>
      <Show above="lg">
        <Stack py={"3rem"}>
          <SideLink
            href="shopping-cart"
            text={messages[locale].shoppingCart}
            icon={"FaShoppingCart"}
          />
          <SideLink
            href="orders"
            text={messages[locale].myOrders}
            icon={"FaShoppingBag"}
          />
          <SideLink
            href="account-settings"
            text={messages[locale].settings}
            icon={"CiSettings"}
          />
          <SideLink
            href="my-addresses"
            text={messages[locale].address}
            icon={"MdLocationPin"}
          />
          {/* <SideLink
            href="notification-settings"
            text={messages[locale].notifications}
            icon={"FaRegBell"}
          /> */}
          <Divider my={3} />
          <form onSubmit={handleSignOut}>
            <button type="submit">
              <HStack
                fontSize={15}
                px={4}
                py={2}
                borderRadius={8}
                w={{ base: 180, lg: 250 }}
                h={"fit-content"}
                gap={3}
                _hover={{ bg: "#eeeeee" }}
              >
                <Text>
                  <MdLogout />
                </Text>
                <Text>
                  <FormattedMessage id="signOut" />
                </Text>
              </HStack>
            </button>
          </form>
        </Stack>
        <Box w={"1px"} backgroundColor={"#eef1f6"}></Box>
      </Show>
      <Box py={"3rem"} w={"100%"}>
        {children}
      </Box>
    </Flex>
  );
};

export default MyAccountLayout;
