import { createContext, ReactNode } from "react";
import { locales } from "../i18n";
import { useState, useEffect } from "react";

export const LangContext = createContext<any>(locales.french);

const LanguageProviderContext = ({ children }: { children: ReactNode }) => {
  const [locale, setLocale] = useState(locales.french);
  const [direction, setDirection] = useState("ltr");

  useEffect(() => {
    const currentLocale = localStorage.getItem("locale");
    if (!currentLocale) return;
    else {
      if (currentLocale === "ar-ma") setDirection("rtl");
      else if (currentLocale === "fr-ca") setDirection("ltr");
      setLocale(currentLocale);
    }
  }, []);

  const changeLocale = (value: string) => {
    localStorage.setItem("locale", value);
    if (value === "ar-ma") setDirection("rtl");
    else if (value === "fr-ca") setDirection("ltr");
    setLocale(value);
  };

  return (
    <LangContext.Provider value={{ locale, changeLocale, direction }}>
      <div className="App" dir={direction}>
        {children}
      </div>
    </LangContext.Provider>
  );
};

export default LanguageProviderContext;
