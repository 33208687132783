import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../contexts/AuthProviderContext";
import { postRequest } from "../../utils/db";
import { Stack, Heading, Flex, Text, Button } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import ShippingOptions from "./ShippingOptions";
import AddressSelector from "./AddressSelector";
import PaymentSelector from "./PaymentSelector";

const AddressAndPayment = () => {
  const { getSession } = useContext(AuthContext);
  const [addresses, setAddresses] = useState([]);

  const fetchData = async () => {
    const session = await getSession();
    if (session) {
      const data = await postRequest(
        `/api/addresses/client/${session.data.id}`,
        {},
        session.data.access_token
      );
      if (!data.error) {
        setAddresses(data);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const isDisabled = addresses.length === 0 ? true : false;

  return (
    <ShippingOptions>
      <Stack gap={7}>
        <Stack gap={7}>
          <Heading as="h2" size="lg">
            <FormattedMessage id="selectAddress" />
          </Heading>
          <AddressSelector addresses={addresses} />
        </Stack>
        <Stack gap={7}>
          <Heading as="h2" size="lg">
            <FormattedMessage id="selectPayment" />
          </Heading>
          <PaymentSelector />
        </Stack>
        <Flex gap={7} justifyContent={"flex-end"}>
          <Button colorScheme="green" type="submit" isDisabled={isDisabled}>
            <FormattedMessage id="execute" />
          </Button>
        </Flex>
      </Stack>
    </ShippingOptions>
  );
};

export default AddressAndPayment;
