import { Flex, Stack, Image, Heading, Text } from "@chakra-ui/react";
import LabelBagde from "./LabelBagde";
import ShopNowButton from "./ShopNowButton";
import { FormattedMessage } from "react-intl";

const SlideTwo = () => {
  return (
    <Flex
      mx={{ base: 3, md: 55 }}
      my={{ base: 3, md: 130 }}
      justifyContent={{ base: "center", sm: "space-between" }}
      wrap={{ base: "wrap-reverse", md: "nowrap" }}
      overflow={"hidden"}
      // gap={3}
    >
      <Stack w={450} gap={5}>
        <LabelBagde>
          <FormattedMessage id="sildeTwoBagde" />
        </LabelBagde>
        <Heading as="h2" size={{ base: "xl", lg: "2xl" }}>
          <FormattedMessage id="sildeTwoHeading" />
          &nbsp;
          <span style={{ color: "#f1c232" }}>
            <FormattedMessage id="sildeTwoHeadingPrice" />
          </span>
        </Heading>
        <Text fontSize={20} fontWeight={"semibold"}>
          <FormattedMessage id="sildeTwoText" />
        </Text>
        <ShopNowButton>
          <FormattedMessage id="shopNowButton" />
        </ShopNowButton>
      </Stack>
      <Image
        alt={""}
        src="/images/slider/slideTwo.png"
        boxSize={{ base: 300, md: 350, "2xl": 570 }}
      />
    </Flex>
  );
};

export default SlideTwo;
