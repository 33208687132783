import { Flex, Heading, Input, Stack, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { FaQuestionCircle } from "react-icons/fa";
import { MdOutlineHeadsetMic } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { LangContext } from "../../contexts/LanguageProviderContext";
import messages from "../../i18n/messages";

const OrdersFollowUp = () => {
  const { locale } = useContext(LangContext);
  return (
    <Stack gap={5} py={10} mx={21}>
      <Heading as="h3" size="lg">
        <FormattedMessage id="question" />
      </Heading>
      <Flex backgroundColor={"rgb(240,243,242)"} p={10} borderRadius={10}>
        <Flex flexDir={"column"} gap={3}>
          <Flex gap={2}>
            <Text fontSize={41} color={"rgb(91,108,117)"}>
              <FaQuestionCircle />
            </Text>
            <Text fontSize={22} fontWeight={"semibold"}>
              <FormattedMessage id="FAQTitle" />
            </Text>
          </Flex>
          <Text fontSize={15} color={"#797b7e"} fontWeight={"semibold"}>
            <FormattedMessage id="FAQDesc" />
          </Text>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const formData = new FormData(e.currentTarget);
              const question = formData.get("question");
              console.log(question);
            }}
          >
            <Input
              name="question"
              placeholder={messages[locale].FAQInputPlaceholder}
              backgroundColor={"white"}
            />
          </form>
        </Flex>
      </Flex>
      <Flex backgroundColor={"rgb(240,243,242)"} p={10} borderRadius={10}>
        <Flex flexDir={"column"} gap={3}>
          <Flex gap={2}>
            <Text fontSize={41} color={"rgb(91,108,117)"}>
              <MdOutlineHeadsetMic />
            </Text>
            <Text fontSize={22} fontWeight={"semibold"}>
              <FormattedMessage id="callUsTitle" />
            </Text>
          </Flex>
          <Text fontSize={15} color={"#797b7e"} fontWeight={"semibold"}>
            <FormattedMessage id="callUsDesc" />
          </Text>
          <Heading
            as="h3"
            size="lg"
            dir="ltr"
            textAlign={locale === "ar-ma" ? "right" : "left"}
          >
            {`05 00 00 00 00`}
          </Heading>
        </Flex>
      </Flex>
    </Stack>
  );
};

export default OrdersFollowUp;
