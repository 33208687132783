import {
  Show,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import { ItemProps } from "../../../data/types";
import { FormattedMessage } from "react-intl";

const InformationTab = ({ product }: { product: ItemProps }) => {
  return (
    <Stack>
      <Text fontWeight={"semibold"} fontSize={22} py={2}>
        <FormattedMessage id="informationTab" />
      </Text>
      <TableContainer w={{ base: "100%", lg: "50%" }} fontSize={16}>
        <Table variant="striped" colorScheme="gray" color={"#5b5b5b"}>
          <Tbody>
            <Tr>
              <Td>
                <Text fontWeight={"semibold"}>
                  <FormattedMessage id="weight" />
                </Text>
                <Show below="md">
                  <Text px={10} pt={3}>
                    {product?.weight}
                  </Text>
                </Show>
              </Td>
              <Show above="md">
                <Td textAlign={"center"}>{product?.weight}</Td>
              </Show>
            </Tr>
            <Tr>
              <Td>
                <Text fontWeight={"semibold"}>
                  <FormattedMessage id="ingredient" />
                </Text>
                <Show below="md">
                  <Text px={10} pt={3}>
                    {product?.categoryName}
                  </Text>
                </Show>
              </Td>
              <Show above="md">
                <Td textAlign={"center"}>{product?.categoryName}</Td>
              </Show>
            </Tr>
            <Tr>
              <Td>
                <Text fontWeight={"semibold"}>
                  <FormattedMessage id="units" />
                </Text>
                <Show below="md">
                  <Text px={10} pt={3}>
                    {product?.unites}
                  </Text>
                </Show>
              </Td>
              <Show above="md">
                <Td textAlign={"center"}>{product?.unites}</Td>
              </Show>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default InformationTab;
