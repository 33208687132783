import React, { useContext, useEffect, useState } from "react";
import MyAccountLayout from "../components/MyAccountLayout";
import { Stack, Heading } from "@chakra-ui/react";
import OrdersTable from "../components/Orders/OrdersTable";
import { FormattedMessage } from "react-intl";
import { AuthContext } from "../contexts/AuthProviderContext";
import { postRequest } from "../utils/db";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const { getSession } = useContext(AuthContext);

  const fetchData = async () => {
    const session = await getSession();
    if (session) {
      const data = await postRequest(
        `/api/orders/client/getOrders/${session.data.id}`,
        {},
        session.data.access_token
      );
      if (!data.error) {
        setOrders(data);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <MyAccountLayout>
      <Stack gap={7} w={"100%"}>
        <Heading as="h2" size="lg">
          <FormattedMessage id="orders" />
        </Heading>
        <OrdersTable orders={orders} />
      </Stack>
    </MyAccountLayout>
  );
};

export default Orders;
