import { Button, useToast } from "@chakra-ui/react";
import { handleDeleteAddress } from "./AddressForm/action";
import { FormEvent, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthProviderContext";
import { LangContext } from "../../contexts/LanguageProviderContext";
import messages from "../../i18n/messages";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ParamsContext } from "../../contexts/ParamsProviderContext";
import { SearchParams } from "../../data/types";

const DeleteAddress = ({ id }: { id: number }) => {
  const toast = useToast();
  const [session, setSession] = useState();
  const { getSession } = useContext(AuthContext);
  const { locale } = useContext(LangContext);
  const { setSearchParams } = useContext(ParamsContext);

  const nav = useNavigate();

  const fetchSession = async () => {
    const data = await getSession();
    if (data) setSession(data);
  };

  useEffect(() => {
    fetchSession();
  }, []);

  const deleteAddress = async (e: FormEvent<HTMLElement>) => {
    e.preventDefault();
    const data = await handleDeleteAddress(id, session);
    if (!data?.error) {
      toast({
        description: messages[locale].errorDeleting,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    } else {
      toast({
        description: messages[locale].successDeleting,
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      setSearchParams((prev: SearchParams) => ({
        ...prev,
        updateAddressKey: 3,
      }));
    }
  };

  return (
    <form onSubmit={deleteAddress}>
      <Button type="submit" size="sm" colorScheme="red">
        <FormattedMessage id="delete" />
      </Button>
    </form>
  );
};

export default DeleteAddress;
