import {
  Box,
  HStack,
  Popover,
  PopoverTrigger,
  useDisclosure,
  Text,
  PopoverContent,
  PopoverBody,
  Flex,
  Link,
} from "@chakra-ui/react";
import { useContext } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { LangContext } from "../../../../contexts/LanguageProviderContext";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

const Menu4NestedMenu = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { locale } = useContext(LangContext);
  const nav = useNavigate();

  const ArrowIcon = locale === "ar-ma" ? IoIosArrowBack : IoIosArrowForward;
  const placement = locale === "ar-ma" ? "left" : "right";

  return (
    <Popover isOpen={isOpen} placement={placement}>
      <PopoverTrigger>
        <Box onMouseEnter={onOpen} onMouseLeave={onClose} w={"100%"}>
          <HStack justifyContent={"space-between"}>
            <Text>
              <FormattedMessage id="menu4Item4" />
            </Text>
            <ArrowIcon />
          </HStack>
        </Box>
      </PopoverTrigger>
      <PopoverContent
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        w={"fit-content"}
        borderColor={"whitesmoke"}
      >
        <PopoverBody>
          <Flex flexDir={"column"}>
            <LinkItem
              onClick={() => {
                nav(`/shopping-cart`);
                onClose();
              }}
            >
              <FormattedMessage id="shoppingCart" />
            </LinkItem>
            <LinkItem
              onClick={() => {
                nav(`/orders`);

                onClose();
              }}
            >
              <FormattedMessage id="menu4Item4Nested1" />
            </LinkItem>
            <LinkItem
              onClick={() => {
                nav(`/account-settings`);

                onClose();
              }}
            >
              <FormattedMessage id="menu4Item4Nested2" />
            </LinkItem>
            <LinkItem
              onClick={() => {
                nav(`/my-addresses`);

                onClose();
              }}
            >
              <FormattedMessage id="menu4Item4Nested3" />
            </LinkItem>
            <LinkItem
              onClick={() => {
                nav(`/notification-settings`);
                onClose();
              }}
            >
              <FormattedMessage id="menu4Item4Nested5" />
            </LinkItem>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default Menu4NestedMenu;

const LinkItem = ({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick?: () => void;
}) => {
  return (
    <Link onClick={onClick} textDecoration={"none"}>
      <Box _hover={{ bg: "#EDF2F7" }} p={2}>
        {children}
      </Box>
    </Link>
  );
};
