import { useContext } from "react";
import { LangContext } from "../../../contexts/LanguageProviderContext";
import { CartContext } from "../../../contexts/ShoppingCartProviderContext";
import { CartItemProps } from "../../../data/types";
import {
  Stack,
  HStack,
  Button,
  Divider,
  Image,
  Text,
  Link,
} from "@chakra-ui/react";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import messages from "../../../i18n/messages";
import RemoveFromCart from "./RemoveFromCart";
import { useNavigate } from "react-router-dom";

const CartItem = ({
  item,
  onClose,
}: {
  item: CartItemProps;
  onClose?: () => void;
}) => {
  const { addQty, decreseQty } = useContext(CartContext);
  const { locale, direction } = useContext(LangContext);
  const isSale = item.totalNormalPrice > item.totalSellingPrice ? true : false;
  const nav = useNavigate();
  return (
    <Stack dir={direction}>
      <HStack w={"100%"} justifyContent={"space-between"}>
        <HStack>
          <Link
            onClick={() => {
              nav(`/shopping-items/${item.id}`);
              if (onClose) onClose();
            }}
          >
            <Image
              src={`https://srv14.optimgov.com/images/${item.image}`}
              alt="cart-item-image"
              boxSize={"80px"}
            />
          </Link>
          <Stack gap={0}>
            <Text fontWeight={"semibold"} fontSize={14}>
              {item.name}
            </Text>
            <RemoveFromCart item={item} />
          </Stack>
        </HStack>
        <HStack gap={5}>
          <HStack>
            <Button
              px={1}
              bg={"white"}
              _hover={{ bg: "white" }}
              fontSize={25}
              cursor={"pointer"}
              onClick={() => addQty(item.id)}
            >
              <CiSquarePlus />
            </Button>
            <Text>{item.qty}</Text>
            <Button
              px={1}
              bg={"white"}
              _hover={{ bg: "white" }}
              fontSize={25}
              cursor={"pointer"}
              onClick={() => {
                if (item.qty > 1) decreseQty(item.id);
              }}
            >
              <CiSquareMinus />
            </Button>
          </HStack>
          <Stack fontSize={16} fontWeight={"semibold"} gap={0}>
            {isSale && (
              <Text>{`${item.totalSellingPrice} ${messages[locale].currency}`}</Text>
            )}
            <Text
              color={isSale ? "#9c9c9c" : "black"}
              textDecoration={isSale ? "line-through" : "none"}
            >{`${item.totalNormalPrice} ${messages[locale].currency}`}</Text>
          </Stack>
        </HStack>
      </HStack>
      <Divider />
    </Stack>
  );
};

export default CartItem;
