import { Stack, Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import ReviewsContainer from "./ReviewsContainer";
import { ItemProps, Review } from "../../../../../data/types";

const Reviews = ({
  product,
  reviews,
}: {
  product: ItemProps;
  reviews: Review[];
}) => {
  return (
    <Stack gap={3}>
      <Text fontWeight={"semibold"} fontSize={24} pb={6}>
        <FormattedMessage id="reviews" />
      </Text>
      <ReviewsContainer product={product} reviews={reviews} />
    </Stack>
  );
};

export default Reviews;
