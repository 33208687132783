import { Box, Flex, HStack, Stack, Text } from "@chakra-ui/react";
import ClientsRatings from "./ClientsRatings";
import Reviews from "./Reviews";
import { ItemProps, Review } from "../../../../data/types";
import { useContext, useEffect, useState } from "react";
import { getRequest } from "../../../../utils/db";
import { AuthContext } from "../../../../contexts/AuthProviderContext";
import { FormattedMessage } from "react-intl";
import AddNewReview from "./AddNewReview";

const ReviewsTab = ({ product }: { product: ItemProps }) => {
  const [rev, setRev] = useState<Review[]>([]);
  const [session, setSession] = useState<any>();
  const { getSession } = useContext(AuthContext);

  const fetchData = async () => {
    await getRequest(`/api/reviews/${product.id}`).then((res) => {
      if (res) setRev(res);
    });
    const data = await getSession();
    if (data) setSession(data);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      {rev.length > 0 ? (
        <HStack gap={10} wrap={"wrap"} alignItems={"flex-start"}>
          <Box w={{ base: "100%", lg: "30%" }}>
            <ClientsRatings reviews={rev} product={product} />
          </Box>
          <Box w={{ base: "100%", lg: "65%" }}>
            <Reviews product={product} reviews={rev} />
          </Box>
        </HStack>
      ) : (
        <>
          <Flex w={"100%"} justifyContent={"center"}>
            <Stack>
              <Text fontSize={24}>
                <FormattedMessage id="noReviewsYet" />
              </Text>
              <AddNewReview product={product} />
            </Stack>
          </Flex>
        </>
      )}
    </>
  );
};

export default ReviewsTab;
