import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { LangContext } from "../../contexts/LanguageProviderContext";
import { ParamsContext } from "../../contexts/ParamsProviderContext";
import { MenuLocationProps, SearchParams } from "../../data/types";
import messages from "../../i18n/messages";

const SearchInput = ({ menuLocation, closeDrawer }: MenuLocationProps) => {
  const nav = useNavigate();
  const { locale } = useContext(LangContext);
  const { setSearchParams } = useContext(ParamsContext);
  const [value, setValue] = useState("");

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        setSearchParams((prev: SearchParams) => ({ ...prev, name: value }));
        if (closeDrawer) closeDrawer();
        nav("/shopping-items");
      }}
    >
      <InputGroup w={menuLocation ? "100%" : "35vw"}>
        <Input
          name="search"
          value={value}
          backgroundColor={menuLocation ? "#ffffff" : "#1f275d"}
          borderColor={menuLocation ? "#bcbcbc" : "#1f275d"}
          borderRadius={7}
          placeholder={messages[locale].SearchInput}
          variant="outline"
          color={menuLocation ? "#1f275d" : "#ffffff"}
          _focus={{ bg: "#ffffff", color: "#1f275d" }}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
        {locale === "ar-ma" ? (
          <InputLeftElement color={"#5e6c75"}>
            <BsSearch />
          </InputLeftElement>
        ) : (
          <InputRightElement color={"#5e6c75"}>
            <BsSearch />
          </InputRightElement>
        )}
      </InputGroup>
    </form>
  );
};

export default SearchInput;
