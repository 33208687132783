import { Button, HStack, Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ReviewCard from "./ReviewCard";
import { FormattedMessage } from "react-intl";
import { reviews } from "../../../../../data/reviews";
import { ItemProps, Review } from "../../../../../data/types";
import { getRequest } from "../../../../../utils/db";

const ReviewsContainer = ({
  product,
  reviews,
}: {
  product: ItemProps;
  reviews: Review[];
}) => {
  const [showNum, setShowNum] = useState(4);
  return (
    <>
      {reviews.length > 0 && (
        <Stack gap={5}>
          {reviews.map(
            (review, index) =>
              index < showNum && <ReviewCard key={index} review={review} />
          )}
          <HStack justifyContent={"space-evenly"}>
            <Button
              colorScheme="yellow"
              size="sm"
              isDisabled={showNum <= 4}
              onClick={() => setShowNum(showNum - 4)}
            >
              <FormattedMessage id="loadLess" />
            </Button>
            <Button
              backgroundColor={"#01114d"}
              _hover={{ bg: "#012199" }}
              color={"white"}
              size="sm"
              isDisabled={showNum >= reviews.length}
              onClick={() => setShowNum(showNum + 4)}
            >
              <FormattedMessage id="loadMore" />
            </Button>
          </HStack>
        </Stack>
      )}
    </>
  );
};

export default ReviewsContainer;
