import { useContext } from "react";
import { LangContext } from "../contexts/LanguageProviderContext";
import { I18nProvider } from "../i18n";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { Button, HStack, Stack, Text } from "@chakra-ui/react";
import { bodyPadding } from "../assets/global";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const { locale } = useContext(LangContext);
  const nav = useNavigate();
  return (
    <I18nProvider locale={locale}>
      <NavBar />
      <HStack px={bodyPadding} justifyContent={"center"} py={10}>
        <Stack>
          <Text fontWeight={"semibold"} fontSize={20}>
            <FormattedMessage id="notFountText" />
          </Text>
          <Button
            onClick={() => nav("/")}
            backgroundColor={"rgb(1,17,77)"}
            color={"white"}
            my={3}
            _hover={{
              bg: "white",
              borderColor: "rgb(1,17,77)",
              border: "1px",
              color: "rgb(1,17,77)",
            }}
          >
            <FormattedMessage id="notFoundButton" />
          </Button>
        </Stack>
      </HStack>
      <Footer />
    </I18nProvider>
  );
};

export default NotFound;
