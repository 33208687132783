import {
  Box,
  Button,
  Flex,
  Input,
  Link,
  Text,
  useToast,
} from "@chakra-ui/react";
import { FormEvent, useContext, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthProviderContext";
import { LangContext } from "../../contexts/LanguageProviderContext";
import messages from "../../i18n/messages";
import InputPassword from "../common/form/InputPassword";
import InputConfirmPassword from "../common/form/InputConfirmPassword";

const SignUpForm = () => {
  const toast = useToast();
  const nav = useNavigate();
  const recaptcha = useRef<ReCAPTCHA | null>(null);
  const siteKey = process.env.REACT_APP_SITE_KEY ?? "";
  const { locale } = useContext(LangContext);
  const { signUp } = useContext(AuthContext);

  const handleFormAction = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let captchVal = "";
    if (recaptcha.current) captchVal = recaptcha.current.getValue() ?? "";
    const formData = new FormData(e.currentTarget);

    const pass = formData.get("password");
    const confirmPass = formData.get("confirmPassword");

    // console.log("pass", pass, "confirmPass", confirmPass);
    if (pass !== confirmPass) {
      toast({
        description: messages[locale].confirmPasswordError,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    formData.append("captcha", captchVal);
    const data = await signUp(formData);
    if (data?.error) {
      toast({
        description: messages[locale].signUpError,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    } else {
      toast({
        description: messages[locale].signUpDone,
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      nav("/");
    }
  };
  return (
    <form onSubmit={handleFormAction}>
      <Input
        mr={locale === "fr-ca" ? 1 : 0}
        name="username"
        placeholder={messages[locale].username}
        size="md"
        borderColor={"rgb(1,17,77)"}
        _hover={{ borderColor: "rgb(1,17,77)" }}
        borderWidth={1}
        mb={3}
      />
      <Input
        name="email"
        type="email"
        placeholder={messages[locale].email}
        size="md"
        borderColor={"rgb(1,17,77)"}
        _hover={{ borderColor: "rgb(1,17,77)" }}
        borderWidth={1}
        mb={3}
      />
      <Input
        name="phone"
        placeholder={messages[locale].phone}
        size="md"
        borderColor={"rgb(1,17,77)"}
        _hover={{ borderColor: "rgb(1,17,77)" }}
        borderWidth={1}
        mb={3}
        type="number"
      />
      <InputPassword />
      <InputConfirmPassword />
      <Flex justifyContent={"center"}>
        <ReCAPTCHA sitekey={siteKey} ref={recaptcha} />
      </Flex>
      <Button
        type="submit"
        backgroundColor={"rgb(1,17,77)"}
        color={"white"}
        my={3}
        w={"100%"}
        _hover={{
          bg: "white",
          borderColor: "rgb(1,17,77)",
          border: "1px",
          color: "rgb(1,17,77)",
        }}
      >
        <FormattedMessage id="signUpButton" />
      </Button>
      <Flex gap={1}>
        <Text color={"#818181"}>
          <FormattedMessage id="termsAndConditionText" />
        </Text>
        <Link
          onClick={() => nav("/signup")}
          color={"#cdad00"}
          textDecoration={"none"}
          _hover={{ color: "#01a915" }}
        >
          <FormattedMessage id="termsOfServicesLink" />
        </Link>
        <Text color={"#818181"}>&</Text>
        <Link
          onClick={() => nav("/signup")}
          color={"#cdad00"}
          textDecoration={"none"}
          _hover={{ color: "#01a915" }}
        >
          <FormattedMessage id="privacyPolicy" />
        </Link>
      </Flex>
    </form>
  );
};

export default SignUpForm;
