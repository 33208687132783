import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  HStack,
  Link,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext, useRef } from "react";
import { IoMdClose } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";
import { LangContext } from "../../contexts/LanguageProviderContext";
import { WishlistContext } from "../../contexts/WishlistProviderContext";
import { FormattedMessage } from "react-intl";

const RemoveAllFromWishList = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const { direction } = useContext(LangContext);
  const { removeAllFromWishlist } = useContext(WishlistContext);

  return (
    <>
      <Button
        colorScheme="red"
        size="sm"
        leftIcon={<MdDeleteForever />}
        onClick={onOpen}
      >
        <FormattedMessage id="removeAllText" />
      </Button>

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader dir={direction}>
            <Flex justifyContent={"space-between"} alignItems={"center"}>
              <Text>
                <FormattedMessage id="confirmRemovingAllTitle" />
              </Text>
              <Link onClick={onClose} color={"#000000"}>
                <IoMdClose />
              </Link>
            </Flex>
          </AlertDialogHeader>
          <AlertDialogBody>
            <FormattedMessage id="removeAllConf" />
          </AlertDialogBody>
          <AlertDialogFooter dir={direction}>
            <HStack gap={2}>
              <Button ref={cancelRef} onClick={onClose}>
                <FormattedMessage id="no" />
              </Button>
              <Button
                colorScheme="red"
                ml={3}
                onClick={() => {
                  removeAllFromWishlist();
                  onClose();
                }}
              >
                <FormattedMessage id="yes" />
              </Button>
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default RemoveAllFromWishList;
