import {
  Link,
  Text,
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Flex,
  ModalBody,
  useToast,
} from "@chakra-ui/react";
import { FormEvent, useContext, useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { FormattedMessage } from "react-intl";
import { AuthContext } from "../../../../contexts/AuthProviderContext";
import AddressForm from "../../../MyAddresses/AddressForm";
import SignInForm from "../../../Account/SignInForm";
import { LangContext } from "../../../../contexts/LanguageProviderContext";
import messages from "../../../../i18n/messages";

const AddReviewModal = ({
  openAddReview,
  session,
}: {
  openAddReview: () => void;
  session: any;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { locale, direction } = useContext(LangContext);
  const { signIn } = useContext(AuthContext);
  const toast = useToast();

  const handleFormAction = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data = await signIn(formData);
    if (data?.error) {
      toast({
        description: messages[locale].signInError,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    } else {
      openAddReview();
      onClose();
    }
  };
  return (
    <>
      <Button
        onClick={onOpen}
        backgroundColor={"#000"}
        textColor={"#ffffff"}
        _hover={{ bg: "#444444" }}
      >
        <FormattedMessage id="addYourReview" />
      </Button>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent dir={direction}>
          <ModalHeader dir={direction}>
            <Flex justifyContent={"space-between"} alignItems={"center"}>
              <Text>
                <FormattedMessage id="addYourReview" />
              </Text>
              <Link onClick={onClose} color={"#000000"}>
                <IoMdClose />
              </Link>
            </Flex>
          </ModalHeader>
          <ModalBody>
            {!session && <SignInForm handleSubmit={handleFormAction} />}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddReviewModal;
