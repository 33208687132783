import { Box, Flex, Link, Stack, Text } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { BsClock } from "react-icons/bs";
import { FaBoxOpen } from "react-icons/fa6";
import { FiRefreshCcw } from "react-icons/fi";
import { IoGiftOutline } from "react-icons/io5";
import { bodyPadding } from "../../assets/global";
import { FormattedMessage } from "react-intl";
import { useContext } from "react";
import { LangContext } from "../../contexts/LanguageProviderContext";
import messages from "../../i18n/messages";

const MainPageFooter = () => {
  const { locale } = useContext(LangContext);
  return (
    <Box px={bodyPadding}>
      <Flex py={20} gap={5} justifyContent={"space-between"} flexWrap={"wrap"}>
        <Card icon={BsClock} title={messages[locale].card1Title}>
          <Text>
            <FormattedMessage id="card1TextPart1" />
            <span
              style={{ fontSize: "17px", color: "black", fontWeight: "bold" }}
            >
              &nbsp;
              <FormattedMessage id="card1TextPart2" />
              &nbsp;
            </span>
          </Text>
          <Text>
            <FormattedMessage id="card1TextPart3" />
          </Text>
        </Card>
        <Card icon={IoGiftOutline} title={messages[locale].card2Title}>
          <FormattedMessage id="card2Text" />
        </Card>
        <Card icon={FaBoxOpen} title={messages[locale].card3Text}>
          <FormattedMessage id="card3Text" />
        </Card>
        <Card icon={FiRefreshCcw} title={messages[locale].card4Text}>
          <FormattedMessage id="card4Text" />

          <Link
            href={`/shopping-items`}
            color={"#01a915"}
            textDecoration={"none"}
            _hover={{ color: "#048414" }}
          >
            <FormattedMessage id="card4Link" />
          </Link>
        </Card>
      </Flex>
    </Box>
  );
};

export default MainPageFooter;

const Card = ({
  icon,
  title,
  children,
}: {
  icon: IconType;
  title: string;
  children: React.ReactNode;
}) => {
  const Icon = icon;
  return (
    <Stack w={300}>
      <Box color={"#19b219"} fontSize={35}>
        <Icon />
      </Box>
      <Text fontSize={20} fontWeight={"semibold"}>
        {title}
      </Text>
      <Box color={"#5c6c75"} fontWeight={"semibold"}>
        {children}
      </Box>
    </Stack>
  );
};
