import { HStack, Text } from "@chakra-ui/react";
import messages from "../../i18n/messages";
import { useContext } from "react";
import { LangContext } from "../../contexts/LanguageProviderContext";

const PriceProvider = ({
  sellingPrice,
  normalPrice,
  fontSize,
}: {
  sellingPrice: number;
  normalPrice: number;
  fontSize?: number;
}) => {
  const isSale = sellingPrice < normalPrice ? true : false;
  const { locale } = useContext(LangContext);

  return (
    <HStack fontSize={fontSize ? fontSize : 14} fontWeight={"semibold"}>
      {isSale && <Text>{`${sellingPrice} ${messages[locale].currency}`}</Text>}
      <Text
        color={isSale ? "#9c9c9c" : "black"}
        textDecoration={isSale ? "line-through" : "none"}
      >{`${normalPrice} ${messages[locale].currency}`}</Text>
    </HStack>
  );
};

export default PriceProvider;
