import {
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
} from "@chakra-ui/react";
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { LangContext } from "../../contexts/LanguageProviderContext";
import { AuthContext } from "../../contexts/AuthProviderContext";
import { postRequest } from "../../utils/db";
import messages from "../../i18n/messages";
import { FormattedMessage } from "react-intl";

const UpdateUserData = () => {
  const toast = useToast();
  const { locale } = useContext(LangContext);
  const { getSession } = useContext(AuthContext);
  const [session, setSession] = useState<any>();
  const [formVals, setValues] = useState({
    email: "",
    name: "",
    phone: "",
  });

  const handleValChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  };
  const fetchSession = async () => {
    const { data } = await getSession();
    if (data) {
      setValues({
        email: data?.email,
        name: data?.name,
        phone: data?.phone,
      });
      setSession(data);
    }
  };

  useEffect(() => {
    fetchSession();
  }, []);

  const handleDetails = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const body = {
      id: session.id,
      username: formData.get("name"),
      email: formData.get("email"),
      phone: formData.get("phone"),
    };

    if (body?.username === "" || body?.email === "" || body?.phone === "") {
      toast({
        description: messages[locale].dataIsEmpty,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }

    const data = await postRequest(
      "/api/clients/edit",
      body,
      session?.access_token
    );
    if (data?.error) {
      toast({
        description: messages[locale].updateProfileError,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    } else {
      toast({
        description: messages[locale].updateProfileSuccess,
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return (
    <form onSubmit={handleDetails}>
      <FormControl>
        <FormLabel fontSize={14} fontWeight={"semibold"}>
          <FormattedMessage id="name" />
        </FormLabel>
        <Input
          value={formVals?.name}
          name="name"
          size="md"
          borderWidth={1}
          borderRadius={5}
          mb={3}
          onChange={handleValChange}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontSize={14} fontWeight={"semibold"}>
          <FormattedMessage id="email" />
        </FormLabel>
        <Input
          onChange={handleValChange}
          value={formVals?.email}
          name="email"
          type="email"
          size="md"
          borderWidth={1}
          borderRadius={5}
          mb={3}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontSize={14} fontWeight={"semibold"}>
          <FormattedMessage id="phone" />
        </FormLabel>
        <Input
          value={formVals?.phone}
          name="phone"
          size="md"
          borderWidth={1}
          borderRadius={5}
          mb={3}
          onChange={handleValChange}
        />
      </FormControl>
      <Button type="submit" colorScheme="yellow">
        <FormattedMessage id="saveDetails" />
      </Button>
    </form>
  );
};

export default UpdateUserData;
