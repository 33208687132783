import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import CategoriesSwiper from "../components/HomePage/CategoriesSwiper";
import MainPageFooter from "../components/HomePage/MainPageFooter";
import MostVisitedCats from "../components/HomePage/MostVisitedCats";
import PageSwiper from "../components/HomePage/PageSwiper";
import PopularProducts from "../components/HomePage/PopularProducts";
import { postRequest } from "../utils/db";

const HomePage = () => {
  const [categories, setCategories] = useState([]);
  const fetchData = async () => {
    const data = await postRequest("/api/categories/getCats", {});
    if (!data.error) {
      setCategories(data.content);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box>
      <PageSwiper />
      <CategoriesSwiper categories={categories} />
      <MostVisitedCats categories={categories} />
      <PopularProducts />
      <MainPageFooter />
    </Box>
  );
};

export default HomePage;
