import { Box, Flex, Heading, SimpleGrid } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import MyAccountLayout from "../components/MyAccountLayout";
import AddNewAddressModal from "../components/MyAddresses/AddNewAddressModal";
import AddressCard from "../components/MyAddresses/AddressCard";
import AddressCardContainer from "../components/MyAddresses/AddressCardContainer";
import { AuthContext } from "../contexts/AuthProviderContext";
import { AddressProps } from "../data/types";
import { postRequest } from "../utils/db";
import { ParamsContext } from "../contexts/ParamsProviderContext";

const MyAddresses = () => {
  const { getSession } = useContext(AuthContext);
  const [addresses, setAddresses] = useState([]);
  const { searchParams } = useContext(ParamsContext);

  const fetchAddresses = async () => {
    const session = await getSession();
    if (session) {
      const data = await postRequest(
        `/api/addresses/client/${session.data.id}`,
        {},
        session.data.access_token
      );
      if (!data.error) {
        setAddresses(data);
      }
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, [searchParams.updateAddressKey]);

  return (
    <MyAccountLayout>
      <Box>
        <Flex justifyContent={"space-between"} pb={10}>
          <Heading as="h2" size="lg">
            <FormattedMessage id="myAddresses" />
          </Heading>
          <AddNewAddressModal />
        </Flex>
        <SimpleGrid columns={{ sm: 1, md: 2, "2xl": 3 }} spacing={6}>
          {addresses.map((address: AddressProps, index: number) => (
            <AddressCardContainer key={index}>
              <AddressCard data={address} />
            </AddressCardContainer>
          ))}
        </SimpleGrid>
      </Box>
    </MyAccountLayout>
  );
};

export default MyAddresses;
