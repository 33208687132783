import {
  Button,
  Flex,
  HStack,
  Stack,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { FormEvent, useContext, useEffect, useState } from "react";
import { MdOutlineStar, MdOutlineStarBorder } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { ItemProps } from "../../../../data/types";
import { AuthContext } from "../../../../contexts/AuthProviderContext";
import { postRequest } from "../../../../utils/db";
import messages from "../../../../i18n/messages";
import { LangContext } from "../../../../contexts/LanguageProviderContext";

const RatingSelector = ({ product }: { product: ItemProps }) => {
  const { getSession } = useContext(AuthContext);
  const { locale } = useContext(LangContext);
  const [score, setScore] = useState<number>(0);
  const [session, setSession] = useState<any>();
  const toast = useToast();

  const fetchSession = async () => {
    const data = await getSession();
    if (data) setSession(data);
  };

  useEffect(() => {
    fetchSession();
  }, []);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    if (score < 1) {
      toast({
        description: messages[locale].pleaseSelectScore,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    } else {
      const params = {
        productId: product.id,
        clientId: session.id,
        productName: product.name,
        email: "ahmed@bahaa.com",
        review: formData.get("review"),
        rating: score,
      };
      const data = await postRequest(
        `/api/reviews`,
        params,
        session?.data.access_token
      );
      if (!data?.error) {
        toast({
          description: messages[locale].addReviewSuccessMsg,
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      } else {
        toast({
          description: messages[locale].addReviewErrorMsg,
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }
    }
  };
  return (
    <Stack pb={5}>
      <HStack
        color={"#ffc107"}
        fontSize={48}
        w={"100%"}
        justifyContent={"center"}
      >
        <Flex cursor={"pointer"} onClick={() => setScore(1)}>
          {score < 1 && <MdOutlineStarBorder />}
          {score >= 1 && <MdOutlineStar />}
        </Flex>
        <Flex cursor={"pointer"} onClick={() => setScore(2)}>
          {score < 2 && <MdOutlineStarBorder />}
          {score >= 2 && <MdOutlineStar />}
        </Flex>
        <Flex cursor={"pointer"} onClick={() => setScore(3)}>
          {score < 3 && <MdOutlineStarBorder />}
          {score >= 3 && <MdOutlineStar />}
        </Flex>
        <Flex cursor={"pointer"} onClick={() => setScore(4)}>
          {score < 4 && <MdOutlineStarBorder />}
          {score >= 4 && <MdOutlineStar />}
        </Flex>
        <Flex cursor={"pointer"} onClick={() => setScore(5)}>
          {score < 5 && <MdOutlineStarBorder />}
          {score >= 5 && <MdOutlineStar />}
        </Flex>
      </HStack>
      <form onSubmit={handleSubmit}>
        <Textarea
          required
          name="review"
          placeholder={"Your Comment"}
          size="md"
          borderColor={"rgb(1,17,77)"}
          _hover={{ borderColor: "rgb(1,17,77)" }}
          borderWidth={1}
          mb={3}
        />
        <Button
          w={"100%"}
          type="submit"
          backgroundColor={"#000"}
          textColor={"#ffffff"}
          _hover={{ bg: "#444444" }}
        >
          <FormattedMessage id="addYourReview" />
        </Button>
      </form>
    </Stack>
  );
};

export default RatingSelector;
