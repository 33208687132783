import { Box, Flex, HStack, Link, Text } from "@chakra-ui/react";
import {
  Category,
  MenuLocationProps,
  SearchParams,
} from "../../../../data/types";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ParamsContext } from "../../../../contexts/ParamsProviderContext";

const Menu3MoreCategoriesDetails = ({
  closeDrawer,
  categoreis,
}: MenuLocationProps & { categoreis: Category[] }) => {
  const nav = useNavigate();
  const { setSearchParams } = useContext(ParamsContext);

  const parentCategories = categoreis.filter(
    (cat) => cat.parentCategoryId === 0
  );

  const getChildCategories = (parentCatId: number) => {
    const childCats = categoreis.filter(
      (cat) => cat.parentCategoryId === parentCatId
    );
    return childCats.map((item) => (
      <LinkItem
        key={item.id}
        onClick={() => {
          setSearchParams((prev: SearchParams) => ({
            ...prev,
            catId: item.id,
            catName: item.name,
          }));
          nav("/shopping-items");
        }}
      >
        {item.name}
      </LinkItem>
    ));
  };

  return (
    <HStack
      w={800}
      flexWrap={"wrap"}
      p={3}
      color={"#000000"}
      onClick={() => {
        if (closeDrawer) closeDrawer();
      }}
    >
      {parentCategories.map((cat, index) => (
        <Flex key={index} flexDir={"column"} w={250} alignSelf={"flex-start"}>
          <Text color={"#eac102"} fontWeight={"bolder"}>
            {cat.name}
          </Text>
          {getChildCategories(cat.id)}
        </Flex>
      ))}
    </HStack>
  );
};

export default Menu3MoreCategoriesDetails;

const LinkItem = ({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick?: () => void;
}) => {
  return (
    <Link onClick={onClick}>
      <Box _hover={{ bg: "#EDF2F7" }} p={2} borderRadius={10} w={"100%"}>
        {children}
      </Box>
    </Link>
  );
};
