import { Flex, Stack, Image, Text, Heading } from "@chakra-ui/react";
import LabelBagde from "./LabelBagde";
import ShopNowButton from "./ShopNowButton";
import { FormattedMessage } from "react-intl";

const SlideOne = () => {
  return (
    <Flex
      mx={{ base: 3, md: 55 }}
      my={{ base: 3, md: 130 }}
      justifyContent={{ base: "center", sm: "space-between" }}
      // justifyContent={"space-between"}
      wrap={{ base: "wrap-reverse", md: "nowrap" }}
      overflow={"hidden"}
      gap={3}
    >
      <Stack
        // minW={400}
        w={450}
        gap={5}
      >
        <LabelBagde>
          <FormattedMessage id="slideOneBagde" />
        </LabelBagde>
        <Heading as="h2" size={{ base: "xl", lg: "2xl" }}>
          <FormattedMessage id="slideOneHeading" />
        </Heading>
        <Text fontSize={20} fontWeight={"semibold"}>
          <FormattedMessage id="slideOneText" />
        </Text>
        <ShopNowButton>
          <FormattedMessage id="shopNowButton" />
        </ShopNowButton>
      </Stack>
      <Image
        alt={""}
        src="/images/slider/slideOne.png"
        boxSize={{ base: 300, md: 350, "2xl": 570 }}
      />
    </Flex>
  );
};

export default SlideOne;
