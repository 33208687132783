import {
  Divider,
  HStack,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { FaCaretDown } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { AuthContext } from "../../../../contexts/AuthProviderContext";
import { NavBarProps } from "../../../../data/types";
import Menu4NestedMenu from "./Menu4NestedMenu";
import { useNavigate } from "react-router-dom";

const Menu4Account: React.FC<NavBarProps> = ({
  session,
  menuLocation,
  closeDrawer,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showLinks, setShowLinks] = useState(false);
  const { signOut } = useContext(AuthContext);
  const nav = useNavigate();

  return (
    <>
      <Menu isOpen={isOpen}>
        <MenuButton
          transition="all 0.2s"
          borderRadius="md"
          onMouseEnter={() => {
            if (!menuLocation) onOpen();
          }}
          onMouseLeave={onClose}
          w={menuLocation === "side" ? "100%" : "inherit"}
          onClick={() => {
            if (menuLocation) setShowLinks(!showLinks);
          }}
        >
          <HStack justifyContent={"space-between"}>
            <Text>
              <FormattedMessage id="menuAccount" />
            </Text>
            <FaCaretDown />
          </HStack>
        </MenuButton>
        <MenuList
          color={"black"}
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
          zIndex={1000}
        >
          {!session && (
            <>
              <Link
                onClick={() => {
                  nav(`/signin`);
                  onClose();
                }}
              >
                <MenuItem>
                  <FormattedMessage id="menu4Item1" />
                </MenuItem>
              </Link>
              <Link
                onClick={() => {
                  nav(`/signup`);
                  onClose();
                }}
              >
                <MenuItem>
                  <FormattedMessage id="menu4Item2" />
                </MenuItem>
              </Link>
              <Link
                onClick={() => {
                  nav(`/reset-password`);
                  onClose();
                }}
              >
                <MenuItem>
                  <FormattedMessage id="menu4Item3" />
                </MenuItem>
              </Link>
            </>
          )}
          {session && (
            <MenuItem>
              <Menu4NestedMenu />
            </MenuItem>
          )}
        </MenuList>
      </Menu>
      {showLinks && !session && (
        <Stack
          px={3}
          py={2}
          color={"#000000"}
          w={"100%"}
          borderRadius={8}
          borderWidth={1}
          borderColor={"#bcbcbc"}
        >
          <Link
            onClick={() => {
              if (closeDrawer) closeDrawer();
              nav("/signin");
            }}
            py={1}
            px={2}
            borderRadius={5}
            _hover={{ bg: "#eeeeee" }}
          >
            <FormattedMessage id="menu4Item1" />
          </Link>
          <Link
            onClick={() => {
              if (closeDrawer) closeDrawer();
              nav("/signup");
            }}
            py={1}
            px={2}
            borderRadius={5}
            _hover={{ bg: "#eeeeee" }}
          >
            <FormattedMessage id="menu4Item2" />
          </Link>
          <Link
            onClick={() => {
              if (closeDrawer) closeDrawer();
              nav("/reset-password");
            }}
            py={1}
            px={2}
            borderRadius={5}
            _hover={{ bg: "#eeeeee" }}
          >
            <FormattedMessage id="menu4Item3" />
          </Link>
        </Stack>
      )}
      {showLinks && session && (
        <Stack
          px={3}
          py={2}
          color={"#000000"}
          w={"100%"}
          borderRadius={8}
          borderWidth={1}
          borderColor={"#bcbcbc"}
        >
          <Link
            onClick={() => {
              if (closeDrawer) closeDrawer();
              nav("/reset-password");
            }}
            py={1}
            px={2}
            borderRadius={5}
            _hover={{ bg: "#eeeeee" }}
          >
            <FormattedMessage id="shoppingCart" />
          </Link>
          <Link
            onClick={() => {
              if (closeDrawer) closeDrawer();
              nav("/orders");
            }}
            py={1}
            px={2}
            borderRadius={5}
            _hover={{ bg: "#eeeeee" }}
          >
            <FormattedMessage id="menu4Item4Nested1" />
          </Link>
          <Link
            onClick={() => {
              if (closeDrawer) closeDrawer();
              nav("/account-settings");
            }}
            py={1}
            px={2}
            borderRadius={5}
            _hover={{ bg: "#eeeeee" }}
          >
            <FormattedMessage id="menu4Item4Nested2" />
          </Link>
          <Link
            onClick={() => {
              if (closeDrawer) closeDrawer();
              nav("/my-addresses");
            }}
            py={1}
            px={2}
            borderRadius={5}
            _hover={{ bg: "#eeeeee" }}
          >
            <FormattedMessage id="menu4Item4Nested3" />
          </Link>
          <Link
            onClick={() => {
              if (closeDrawer) closeDrawer();
              nav("/notification-settings");
            }}
            py={1}
            px={2}
            borderRadius={5}
            _hover={{ bg: "#eeeeee" }}
          >
            <FormattedMessage id="menu4Item4Nested5" />
          </Link>
          <Divider my={3} />
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              await signOut();
              if (closeDrawer) closeDrawer();
            }}
          >
            <button type="submit" style={{ width: "100%" }}>
              <HStack
                fontSize={15}
                px={4}
                py={2}
                borderRadius={8}
                w={"100%"}
                h={"fit-content"}
                gap={3}
                _hover={{ bg: "#eeeeee" }}
              >
                <Text>
                  <MdLogout />
                </Text>
                <Text>
                  <FormattedMessage id="signOut" />
                </Text>
              </HStack>
            </button>
          </form>
        </Stack>
      )}
    </>
  );
};

export default Menu4Account;
