import { Flex, HStack, Image, Link, Text } from "@chakra-ui/react";
import AccountPagesHeader from "../components/Account/AccountPagesHeader";
import { FormattedMessage } from "react-intl";
import { bodyPadding } from "../assets/global";
import SignUpForm from "../components/Account/SignUpForm";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const nav = useNavigate();
  return (
    <>
      <AccountPagesHeader>
        <HStack fontWeight={"semibold"}>
          <Text>
            <FormattedMessage id="alreadyHaveAcc" />
          </Text>
          <Link onClick={() => nav(`/signin`)}>
            <FormattedMessage id="signInLink" />
          </Link>
        </HStack>
      </AccountPagesHeader>
      <Flex
        px={bodyPadding}
        justifyContent={"space-evenly"}
        py={"5.6rem"}
        wrap={"wrap"}
      >
        <Image alt={""} src="/images/account/signup.svg" boxSize={250} />
        <Flex flexDir={"column"} gap={8} maxW={450}>
          <Flex flexDir={"column"}>
            <Text fontSize={30} fontWeight={"semibold"} color={"rgb(1,17,77)"}>
              <FormattedMessage id="signUpPage" />
            </Text>
            <Text fontSize={14} fontWeight={"semibold"} color={"#818181"}>
              <FormattedMessage id="signUpDescription" />
            </Text>
          </Flex>
          <SignUpForm />
        </Flex>
      </Flex>
    </>
  );
};

export default SignUp;
