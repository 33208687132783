import {
  Box,
  Divider,
  Flex,
  HStack,
  Image,
  Link,
  Show,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useState, useEffect, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getRequest } from "../utils/db";
import { LangContext } from "../contexts/LanguageProviderContext";
import { ItemProps, SearchParams } from "../data/types";
import { bodyPadding } from "../assets/global";
import { ParamsContext } from "../contexts/ParamsProviderContext";
import AddToWishList from "../components/ShoppingItems/AddToWishList";
import PriceProvider from "../components/ShoppingItems/PriceProvider";
import { FormattedMessage } from "react-intl";
import AddToCartWithQty from "../components/DetailedItem/AddToCartWithQty";
import TabsContainer from "../components/DetailedItem/ItemTab/TabsContainer";
import RecommendedContainer from "../components/DetailedItem/RecommendedItems/RecommendedContainer";
import RelatedArticalsContainer from "../components/DetailedItem/RelatedArticalsContainer";
import ABRSpinner from "../components/common/ABRSpinner";

const DetailedItem = () => {
  const [product, setProduct] = useState<ItemProps>();
  const [products, setProducts] = useState<ItemProps[]>();
  const { locale } = useContext(LangContext);
  const { setSearchParams } = useContext(ParamsContext);
  const [loading, setLoading] = useState<boolean>(true);
  const { id } = useParams();
  const location = useLocation();

  const fetchData = async () => {
    setLoading(true);
    const data = await getRequest(
      `/api/products/getProductsByParam?product_id=${id}`
    ).then((res) => {
      if (res) {
        setProduct(res[0]);
        setLoading(false);
      }
    });

    const allData = await getRequest(`/api/products/getProductsByParam`).then(
      (res) => {
        if (res) setProducts(res);
      }
    );
  };

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const stockAvail = () => {
    if (locale === "fr-ca") {
      if (product?.inStore) return "En stock";
      else return "Épuisé";
    } else if (locale === "ar-ma") {
      if (product?.inStore) return "متاح";
      else return "غير متوفر حالياً";
    }
  };

  return (
    <>
      {product && (
        <Box px={bodyPadding}>
          {loading && (
            <Flex
              w={"100%"}
              h={500}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Spinner
                thickness="10px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                w={"100px"}
                h={"100px"}
                marginX="auto"
                marginY="auto"
              />
            </Flex>
          )}
          {!loading && (
            <>
              <Flex gap={6}>
                <Flex w={{ base: "100%", lg: "80%" }} py={7}>
                  <HStack
                    alignItems={"flex-start"}
                    w={"100%"}
                    wrap={"wrap"}
                    justifyContent={{ base: "center", lg: "flex-start" }}
                  >
                    <Image
                      src={
                        product?.image
                          ? `https://srv14.optimgov.com/images/${product.image}`
                          : ""
                      }
                      boxSize={400}
                      mx={1}
                    />
                    <Stack w={"fit-content"}>
                      <Link
                        // href={`/${localeActive}/shopping-items?catId=${product?.category}`}
                        fontSize={14}
                        fontWeight={"semibold"}
                        color={"#eac102"}
                        onClick={() => {
                          setSearchParams((prev: SearchParams) => ({
                            ...prev,
                            catId: product?.category,
                          }));
                        }}
                      >
                        {product?.categoryName}
                      </Link>
                      <Text fontSize={33} fontWeight={"semibold"}>
                        {product?.name}
                      </Text>
                      <PriceProvider
                        normalPrice={product?.normalPrice!}
                        sellingPrice={product?.sellingPrice!}
                        fontSize={20}
                      />
                      <Divider />
                      <Flex py={3} gap={8} alignItems={"center"}>
                        {product?.inStore && (
                          <AddToCartWithQty item={product!} />
                        )}
                        {!product?.inStore && (
                          <Box
                            fontSize={20}
                            color={"red.500"}
                            fontWeight={"bold"}
                            borderWidth={1}
                            borderColor={"red.500"}
                            borderRadius={5}
                            px={4}
                            py={1}
                          >
                            <FormattedMessage id="outOfStock" />
                          </Box>
                        )}

                        <Box pb={1}>
                          <AddToWishList item={product!} fontSize={30} />
                        </Box>
                      </Flex>
                      <Divider />
                      <Stack
                        gap={5}
                        px={5}
                        py={7}
                        fontSize={16}
                        color={"#999999"}
                      >
                        <HStack>
                          <Text fontWeight={"semibold"}></Text>
                          <Text>{product?.codeProduct}</Text>
                        </HStack>
                        <HStack>
                          <Text fontWeight={"semibold"}>
                            <FormattedMessage id="availability" />
                          </Text>
                          <Text>{stockAvail()}</Text>
                        </HStack>
                        <HStack>
                          <Text fontWeight={"semibold"}>
                            <FormattedMessage id="type" />
                          </Text>
                          <Text>{product?.categoryName}</Text>
                        </HStack>
                      </Stack>
                    </Stack>
                  </HStack>
                </Flex>
                <Show above={"lg"}>
                  <Flex w={"20%"} py={7}>
                    <RecommendedContainer products={products!} />
                  </Flex>
                </Show>
              </Flex>
              <TabsContainer product={product!} />
              <RelatedArticalsContainer catId={product?.category!} />
            </>
          )}
        </Box>
      )}
      {!product && <ABRSpinner />}
    </>
  );
};

export default DetailedItem;
