import { MenuLocationProps } from "../../../data/types";
import { Link } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

const Menu2MainPage = ({ menuLocation, closeDrawer }: MenuLocationProps) => {
  const nav = useNavigate();

  return (
    <Link
      w={menuLocation === "side" ? "100%" : "inherit"}
      onClick={() => {
        if (closeDrawer) closeDrawer();
        nav("/");
      }}
    >
      <FormattedMessage id="menuMainPage" />
    </Link>
  );
};

export default Menu2MainPage;
