import {
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FormEvent, useContext } from "react";
import { IoMdClose } from "react-icons/io";
import { IoPerson } from "react-icons/io5";
import { FormattedMessage } from "react-intl";
import { AuthContext } from "../../contexts/AuthProviderContext";
import { LangContext } from "../../contexts/LanguageProviderContext";
import { NavBarProps } from "../../data/types";
import messages from "../../i18n/messages";
import CountBadge from "../common/CountBadge";
import SignInForm from "../Account/SignInForm";
import { useNavigate } from "react-router-dom";

const ProfileBadge: React.FC<NavBarProps> = ({ session }) => {
  const nav = useNavigate();
  return (
    <CountBadge>
      {!session && <LoginModel />}
      {session && (
        <Link fontSize={20} onClick={() => nav("/account-settings")}>
          <IoPerson />
        </Link>
      )}
    </CountBadge>
  );
};

export default ProfileBadge;

const LoginModel = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { direction, locale } = useContext(LangContext);
  const { signIn } = useContext(AuthContext);
  const toast = useToast();
  const nav = useNavigate();

  const handleFormAction = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data = await signIn(formData);
    if (data?.error) {
      toast({
        description: messages[locale].signInError,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    } else {
      nav("/account-settings");
      onClose();
    }
  };

  return (
    <>
      <Link onClick={onOpen} fontSize={20}>
        <IoPerson />
      </Link>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent dir={direction}>
          <ModalHeader dir={direction}>
            <Flex justifyContent={"space-between"} alignItems={"center"}>
              <Text>
                <FormattedMessage id="signInButton" />
              </Text>
              <Link onClick={onClose} color={"#000000"}>
                <IoMdClose />
              </Link>
            </Flex>
          </ModalHeader>
          <ModalBody>
            <SignInForm handleSubmit={handleFormAction} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
