import { Flex, Link, Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

const ForCustomers = () => {
  const linkColor = "#C0C3CA";
  const hoverColor = "#d6c94b";
  return (
    <Flex flexDir={"column"} alignSelf={"flex-start"} gap={3}>
      <Text fontWeight={"bold"}>
        <FormattedMessage id="forCustomers" />
      </Text>
      <Link
        href="#"
        _hover={{ color: hoverColor }}
        color={linkColor}
        textDecor={"none"}
      >
        <FormattedMessage id="Payments" />
      </Link>
      <Link
        href="#"
        _hover={{ color: hoverColor }}
        color={linkColor}
        textDecor={"none"}
      >
        <FormattedMessage id="Shipping" />
      </Link>
      <Link
        href="#"
        _hover={{ color: hoverColor }}
        color={linkColor}
        textDecor={"none"}
      >
        <FormattedMessage id="productReturns" />
      </Link>
      <Link
        href="#"
        _hover={{ color: hoverColor }}
        color={linkColor}
        textDecor={"none"}
      >
        <FormattedMessage id="faq" />
      </Link>
      <Link
        href="#"
        _hover={{ color: hoverColor }}
        color={linkColor}
        textDecor={"none"}
      >
        <FormattedMessage id="paymentAtCheckout" />
      </Link>
    </Flex>
  );
};

export default ForCustomers;
