import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Link,
  useDisclosure,
  Text,
  HStack,
} from "@chakra-ui/react";
import { useContext, useRef } from "react";
import { IoMdClose } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";
import { CartContext } from "../../../contexts/ShoppingCartProviderContext";
import { LangContext } from "../../../contexts/LanguageProviderContext";
import { FormattedMessage } from "react-intl";

const RemoveAllItemsBtn = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { removeAllItems } = useContext(CartContext);
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const { direction } = useContext(LangContext);

  return (
    <>
      <Button
        colorScheme="red"
        size="sm"
        leftIcon={<MdDeleteForever />}
        onClick={onOpen}
      >
        <FormattedMessage id="removeAllItems" />
      </Button>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader dir={direction}>
            <Flex justifyContent={"space-between"} alignItems={"center"}>
              <Text>
                <FormattedMessage id="removeAllItems" />
              </Text>
              <Link onClick={onClose} color={"#000000"}>
                <IoMdClose />
              </Link>
            </Flex>
          </AlertDialogHeader>
          <AlertDialogBody>
            <FormattedMessage id="removeAllConf" />
          </AlertDialogBody>
          <AlertDialogFooter dir={direction}>
            <HStack gap={2}>
              <Button ref={cancelRef} onClick={onClose}>
                <FormattedMessage id="no" />
              </Button>
              <Button
                colorScheme="red"
                ml={3}
                onClick={() => {
                  removeAllItems();
                  onClose();
                }}
              >
                <FormattedMessage id="yes" />
              </Button>
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default RemoveAllItemsBtn;
