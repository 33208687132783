import {
  HStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Link,
  useToast,
} from "@chakra-ui/react";
import { FormEvent, useContext, useEffect, useState } from "react";
import { LangContext } from "../../contexts/LanguageProviderContext";
import { AuthContext } from "../../contexts/AuthProviderContext";
import { postRequest } from "../../utils/db";
import messages from "../../i18n/messages";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

const ResetPasswordForm = () => {
  const toast = useToast();
  const { locale } = useContext(LangContext);
  const { getSession } = useContext(AuthContext);
  const [session, setSession] = useState<any>();
  const nav = useNavigate();

  const fetchSession = async () => {
    const data = await getSession();
    if (data) setSession(data);
  };

  useEffect(() => {
    fetchSession();
  }, []);

  const resetPassword = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const body = {
      username: session.data.name,
      password: formData.get("newPass"),
    };
    try {
      await postRequest("/api/clients/reset", body, session.data.access_token);
      toast({
        description: messages[locale].changePassSuccess,
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        description: messages[locale].changePassError,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return (
    <form onSubmit={resetPassword}>
      <HStack width={"80%"}>
        <FormControl>
          <FormLabel fontSize={14} fontWeight={"semibold"}>
            <FormattedMessage id="newPass" />
          </FormLabel>
          <Input
            type="password"
            placeholder="**********"
            name="newPass"
            size="md"
            borderWidth={1}
            borderRadius={5}
            mb={3}
          />
        </FormControl>
        <FormControl>
          <FormLabel fontSize={14} fontWeight={"semibold"}>
            <FormattedMessage id="currentPass" />
          </FormLabel>
          <Input
            type="password"
            placeholder="**********"
            name="currentPass"
            size="md"
            borderWidth={1}
            borderRadius={5}
            mb={3}
          />
        </FormControl>
      </HStack>
      <Text fontWeight={"semibold"} maxW={350} color={"#818181"} py={4}>
        <FormattedMessage id="resetPassText" />
        <Link
          onClick={() => nav("/signup")}
          color={"#01a915"}
          textDecoration={"none"}
          _hover={{ color: "#048414" }}
        >
          <FormattedMessage id="resetPassLink" />
        </Link>
      </Text>
      <Button type="submit" colorScheme="yellow">
        <FormattedMessage id="savePassword" />
      </Button>
    </form>
  );
};

export default ResetPasswordForm;
