import { useContext } from "react";
import { I18nProvider } from "../i18n";
import { LangContext } from "../contexts/LanguageProviderContext";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";

const AccountRoutesLayout = () => {
  const { locale } = useContext(LangContext);

  return (
    <I18nProvider locale={locale}>
      <Outlet />
      <Footer />
    </I18nProvider>
  );
};

export default AccountRoutesLayout;
