import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
  Text,
  Flex,
  Link,
  Stack,
} from "@chakra-ui/react";
import { FaCartArrowDown } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import { NavBarProps } from "../../../data/types";
import { useContext } from "react";
import { LangContext } from "../../../contexts/LanguageProviderContext";
import { CartContext } from "../../../contexts/ShoppingCartProviderContext";
import CountBadge from "../../common/CountBadge";
import { FormattedMessage } from "react-intl";
import CartViewer from "./CartViewer";
import ExecuteCart from "./ExecuteCart";

const CartDrawer: React.FC<NavBarProps> = ({ session }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { cartItems } = useContext(CartContext);
  const { direction } = useContext(LangContext);

  return (
    <CountBadge count={cartItems.length}>
      <Box fontSize={23} onClick={onOpen} color={"#f1c232"} cursor={"pointer"}>
        <FaCartArrowDown />
      </Box>
      <Drawer
        onClose={onClose}
        isOpen={isOpen}
        placement={"right"}
        size={{ base: "full", md: "md" }}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader dir={direction} color={"green"}>
            <Flex justifyContent={"space-between"} alignItems={"center"}>
              <Text>
                <FormattedMessage id="drawerTitle" />
              </Text>
              <Link onClick={onClose} color={"#000000"}>
                <IoMdClose />
              </Link>
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <Stack gap={5}>
              <CartViewer onClose={onClose} />
              {cartItems.length > 0 && (
                <ExecuteCart session={session} onClose={onClose} />
              )}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </CountBadge>
  );
};

export default CartDrawer;
