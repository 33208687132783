import { Alert, AlertIcon, Link, Stack, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { LangContext } from "../../../contexts/LanguageProviderContext";
import { CartContext } from "../../../contexts/ShoppingCartProviderContext";
import { CartItemProps } from "../../../data/types";
import CartItem from "./CartItem";
import { useNavigate } from "react-router-dom";

const CartViewer = ({ onClose }: { onClose?: () => void }) => {
  const { cartItems } = useContext(CartContext);
  const { direction } = useContext(LangContext);
  const nav = useNavigate();

  return (
    <Stack dir={direction}>
      {cartItems.length === 0 && (
        <Alert status="warning" borderRadius={6}>
          <AlertIcon />
          <Text>
            <FormattedMessage id="emptyCartText" />
            &nbsp;
            <Link
              textDecoration={"underline !important"}
              onClick={() => {
                if (onClose) onClose();
                nav("/shopping-items");
              }}
            >
              <FormattedMessage id="emptyCartLink" />
            </Link>
          </Text>
        </Alert>
      )}
      {cartItems &&
        cartItems.map((item: CartItemProps, index: number) => (
          <CartItem key={index} item={item} onClose={onClose} />
        ))}
    </Stack>
  );
};

export default CartViewer;
