import {
  Box,
  Flex,
  HStack,
  Link,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa6";
import { FormattedMessage } from "react-intl";
import { LangContext } from "../../contexts/LanguageProviderContext";
import { ParamsContext } from "../../contexts/ParamsProviderContext";
import { ItemProps } from "../../data/types";
import { getRequest } from "../../utils/db";
import ABRSpinner from "../common/ABRSpinner";
import ItemCard from "./ItemCard";
import ItemCardContainer from "./ItemCardContainer";
import ItemsDisplayAndOrder from "./ItemsDisplayAndOrder";

const ItemsGrid = () => {
  const { searchParams } = useContext(ParamsContext);
  const { locale } = useContext(LangContext);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const fetchData = async () => {
    setIsLoading(true);
    const data = await getRequest(
      `/api/products/getProductsByParam?min_price=${
        searchParams.min_price
      }&max_price=${searchParams.max_price}${
        searchParams.catId !== null ? `&cat_id=${searchParams.catId}` : ""
      }${
        searchParams.name !== null ? `&product_name=${searchParams.name}` : ""
      }${searchParams.rating ? `&rating=${searchParams.rating}` : ""}${
        searchParams.priceOrder !== ""
          ? `&proceOrder=${searchParams.priceOrder}`
          : ""
      }${
        searchParams.dateOrder !== ""
          ? `&dateOrder=${searchParams.dateOrder}`
          : ""
      }
      
      `
    );
    if (!data.error) {
      setProducts(data);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchParams]);
  const isPrevPageDisabled = searchParams.page <= 1 ? true : false;
  const isNextPageDisabled =
    searchParams.page < Math.ceil(products.length / searchParams.display)
      ? false
      : true;

  const displayedProducts = products.filter(
    (prod: ItemProps) => prod.sellingPrice > 0
  );

  return (
    <>
      {!isLoading && (
        <Stack gap={5} w={{ base: "100%", lg: "80%" }}>
          {searchParams.catName !== "" && (
            <Box
              backgroundColor={"#f0f3f2"}
              borderRadius={5}
              px={10}
              py={8}
              fontSize={"30px"}
              fontWeight={"semibold"}
            >
              {searchParams.catName}
            </Box>
          )}
          <Flex justifyContent={"space-between"} wrap={"wrap"}>
            <HStack gap={1}>
              <Text fontWeight={"semibold"}>{displayedProducts.length}</Text>
              <Text>
                <FormattedMessage id="productsFound" />
              </Text>
            </HStack>
            <ItemsDisplayAndOrder />
          </Flex>
          <SimpleGrid columns={{ sm: 1, md: 2, xl: 3 }} spacing={6}>
            {products.map(
              (product: ItemProps, index: number) =>
                index < searchParams.page * searchParams.display &&
                index >= searchParams.page - 1 * searchParams.display &&
                product.sellingPrice > 0 && (
                  <ItemCardContainer key={index}>
                    <ItemCard item={product} />
                  </ItemCardContainer>
                )
            )}
          </SimpleGrid>
          <HStack justifyContent={"flex-end"} gap={4}>
            <Box
              borderRadius={7}
              borderColor={"#d9d9d9"}
              borderWidth={1}
              fontSize={24}
              p={2}
              backgroundColor={isPrevPageDisabled ? "#d9d9d9" : "white"}
              color={isPrevPageDisabled ? "#999999" : "black"}
              _hover={{
                bg: !isPrevPageDisabled && "#ffd966",
                color: !isPrevPageDisabled && "#ffffff",
                transform: "scale(1.03)",
                transition: "transform .15s ease-in",
              }}
            >
              {!isPrevPageDisabled ? (
                <Link>
                  {locale === "fr-ca" ? <FaCaretLeft /> : <FaCaretRight />}
                </Link>
              ) : (
                <>{locale === "fr-ca" ? <FaCaretLeft /> : <FaCaretRight />}</>
              )}
            </Box>
            <Text
              borderRadius={7}
              borderColor={"#d9d9d9"}
              borderWidth={1}
              py={2}
              px={4}
              fontSize={15}
              fontWeight={"semibold"}
            >
              {searchParams.page}
            </Text>
            <Box
              borderRadius={7}
              borderColor={"#d9d9d9"}
              borderWidth={1}
              p={2}
              fontSize={24}
              backgroundColor={isNextPageDisabled ? "#d9d9d9" : "white"}
              color={isNextPageDisabled ? "#999999" : "black"}
              _hover={{
                bg: !isNextPageDisabled && "#ffd966",
                color: !isNextPageDisabled && "#ffffff",
                transform: "scale(1.03)",
                transition: "transform .15s ease-in",
              }}
            >
              {!isNextPageDisabled ? (
                <Link>
                  {locale === "fr-ca" ? <FaCaretRight /> : <FaCaretLeft />}
                </Link>
              ) : (
                <>{locale === "fr-ca" ? <FaCaretRight /> : <FaCaretLeft />}</>
              )}
            </Box>
          </HStack>
        </Stack>
      )}
      {isLoading && <ABRSpinner />}
    </>
  );
};

export default ItemsGrid;
