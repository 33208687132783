import { Category, MenuLocationProps } from "../../../../data/types";
import {
  Box,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FaCaretDown } from "react-icons/fa";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import Menu3MoreCategoriesDetails from "./Menu3MoreCategoriesDetails";

const Menu3MoreCategories = ({
  categoreis,
  menuLocation,
  closeDrawer,
}: MenuLocationProps & { categoreis: Category[] }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showLinks, setShowLinks] = useState(false);

  return (
    <>
      <Popover isOpen={isOpen}>
        <PopoverTrigger>
          <Box
            onMouseEnter={() => {
              if (!menuLocation) onOpen();
            }}
            onMouseLeave={onClose}
            w={menuLocation === "side" ? "100%" : "inherit"}
            onClick={() => {
              if (menuLocation) setShowLinks(!showLinks);
            }}
          >
            <HStack justifyContent={"space-between"}>
              <Text cursor={"pointer"}>
                <FormattedMessage id="menuMoreCategories" />
              </Text>
              <FaCaretDown />
            </HStack>
          </Box>
        </PopoverTrigger>
        <PopoverContent
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
          color="black"
          borderColor={"whitesmoke"}
          w={"fit-content"}
        >
          <PopoverBody>
            <Menu3MoreCategoriesDetails categoreis={categoreis} />
          </PopoverBody>
        </PopoverContent>
      </Popover>
      {showLinks && (
        <Menu3MoreCategoriesDetails
          closeDrawer={closeDrawer}
          categoreis={categoreis}
        />
      )}
    </>
  );
};

export default Menu3MoreCategories;
