import { Box, Flex, HStack, Image, Link, Show } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { bodyPadding } from "../../assets/global";
import { AuthContext } from "../../contexts/AuthProviderContext";
import { postRequest } from "../../utils/db";
import CartDrawer from "./CartDrawer";
import LocaleSwitcher from "./LocaleSwitcher";
import Menu from "./Menu";
import SideMenuDrawer from "./Menu/SideMenuDrawer";
import ProfileBadge from "./ProfileBadge";
import SearchInput from "./SearchInput";
import WishListBadge from "./WishListBadge";

const NavBar = () => {
  const location = useLocation();
  const { session } = useContext(AuthContext);
  const [categories, setCategories] = useState([]);
  const nav = useNavigate();

  const fetchData = async () => {
    const data = await postRequest("/api/categories/getCats", {});
    if (!data.error) {
      setCategories(data.content);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {location.pathname.includes("/shopping-items") && <LocaleSwitcher />}
      <Box backgroundColor={"#01114d"} px={bodyPadding}>
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <HStack gap={16} py={5}>
            <Link onClick={() => nav("/")}>
              <HStack minW={100} w={100}>
                <Image alt="logo" src={"/images/header/RM.png"} boxSize={50} />
                <Image
                  alt="logo"
                  src={"/images/header/dgapr.png"}
                  boxSize={50}
                />
              </HStack>
            </Link>
            <Show above="lg">
              <SearchInput />
            </Show>
          </HStack>
          <HStack gap={5}>
            <WishListBadge />
            <CartDrawer session={session} />
            <ProfileBadge session={session} />
            <Show below="lg">
              <SideMenuDrawer session={session} categories={categories} />
            </Show>
          </HStack>
        </Flex>
        <Show above="lg">
          <Menu session={session} categories={categories} />
        </Show>
      </Box>
    </>
  );
};

export default NavBar;
