import { Flex, Heading, Image, Stack, Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

const HelpCenterIntro = () => {
  return (
    <Flex
      py={20}
      wrap={"wrap"}
      justifyContent={"space-evenly"}
      flexDir={"row-reverse"}
      gap={10}
    >
      <Image alt={""} src="/images/assistance.png" boxSize={200} />
      <Stack>
        <Heading as="h2" size="xl">
          <FormattedMessage id="helpCenterTitle" />
        </Heading>
        <Text fontSize={18} fontWeight={"semibold"}>
          <FormattedMessage id="helpCenterDescription" />
        </Text>
        <Text fontSize={15} fontWeight={"semibold"} color={"#797b7e"} w={500}>
          <FormattedMessage id="p" />
        </Text>
      </Stack>
    </Flex>
  );
};

export default HelpCenterIntro;
