import { Card, Flex, Link, Image } from "@chakra-ui/react";
import { ItemProps } from "../../../data/types";
import PriceProvider from "../../ShoppingItems/PriceProvider";
import { useNavigate } from "react-router-dom";

const RecommendedItemCard = ({ item }: { item: ItemProps }) => {
  const nav = useNavigate();
  return (
    <Flex
      flexDir={"column"}
      alignItems={"center"}
      borderWidth={1}
      borderRadius={10}
      borderColor={"#eeeeee"}
    >
      <Link onClick={() => nav(`/shopping-items/${item.id}`)}>
        <Image
          src={
            item.image ? `https://srv14.optimgov.com/images/${item.image}` : ""
          }
          boxSize={200}
          mx={5}
        />
      </Link>
      <PriceProvider
        normalPrice={item.normalPrice}
        sellingPrice={item.sellingPrice}
      />
    </Flex>
  );
};

export default RecommendedItemCard;
