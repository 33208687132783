import { createBrowserRouter } from "react-router-dom";
import Layout from "./Layout";
import HomePage from "./HomePage";
import AccountRoutesLayout from "./AccountRoutesLayout";
import SignIn from "./SignIn";
import AccountSettings from "./AccountSettings";
import CentreAssistance from "./CentreAssistance";
import MyAddresses from "./MyAddresses";
import NotificationSettings from "./NotificationSettings";
import Orders from "./Orders";
import ShoppingCart from "./ShoppingCart";
import ShoppingItems from "./ShoppingItems";
import DetailedItem from "./DetailedItem";
import Wishlist from "./Wishlist";
import SignUp from "./SignUp";
import ResetPassword from "./ResetPassword";
import NotFound from "./NotFound";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <NotFound />,
    children: [
      { index: true, element: <HomePage /> },
      { path: "/account-settings", element: <AccountSettings /> },
      { path: "/centre-assistance", element: <CentreAssistance /> },
      { path: "/my-addresses", element: <MyAddresses /> },
      { path: "/notification-settings", element: <NotificationSettings /> },
      { path: "/orders", element: <Orders /> },
      { path: "/shopping-cart", element: <ShoppingCart /> },
      { path: "/shopping-items", element: <ShoppingItems /> },
      { path: "/shopping-items/:id", element: <DetailedItem /> },
      { path: "/wishlist", element: <Wishlist /> },
    ],
  },
  {
    path: "/signin",
    element: <AccountRoutesLayout />,
    errorElement: <NotFound />,
    children: [{ index: true, element: <SignIn /> }],
  },
  {
    path: "/signup",
    element: <AccountRoutesLayout />,
    errorElement: <NotFound />,
    children: [{ index: true, element: <SignUp /> }],
  },
  {
    path: "/reset-password",
    element: <AccountRoutesLayout />,
    errorElement: <NotFound />,
    children: [{ index: true, element: <ResetPassword /> }],
  },
]);

export default router;
