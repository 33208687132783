import { createContext, ReactNode, useState } from "react";
import { SearchParams } from "../data/types";

export const ParamsContext = createContext<SearchParams | any>({});

const ParamsProviderContext = ({ children }: { children: ReactNode }) => {
  const [searchParams, setSearchParams] = useState({
    catId: null,
    min_price: 0,
    max_price: 1500,
    name: "",
    display: 10,
    evaluation: null,
    orderBy: "",
    page: 1,
    priceOrder: "",
    dateOrder: "",
  });

  return (
    <ParamsContext.Provider value={{ searchParams, setSearchParams }}>
      {children}
    </ParamsContext.Provider>
  );
};

export default ParamsProviderContext;
