import {
  Button,
  Flex,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { MdOutlineStar } from "react-icons/md";
import RatingBar from "./RatingBar";
import ItemEvaluationProvider from "../../../ShoppingItems/ItemEvaluationProvider";
import { FormattedMessage } from "react-intl";
import messages from "../../../../i18n/messages";
import { useContext, useEffect, useState } from "react";
import { LangContext } from "../../../../contexts/LanguageProviderContext";
import { ItemProps, Review } from "../../../../data/types";
import AddReviewModal from "./AddReviewModal";
import { IoMdClose } from "react-icons/io";
import { AuthContext } from "../../../../contexts/AuthProviderContext";
import RatingSelector from "./RatingSelector";
import AddNewReview from "./AddNewReview";

const ClientsRatings = ({
  reviews,
  product,
}: {
  reviews: Review[];
  product: ItemProps;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [session, setSession] = useState<any>();
  const { getSession } = useContext(AuthContext);

  const fetchSession = async () => {
    const data = await getSession();
    if (data) setSession(data);
  };

  useEffect(() => {
    fetchSession();
  }, []);

  const { locale, direction } = useContext(LangContext);
  const getStatistics = () => {
    let total = 0;
    let pres5 = 0;
    let pres4 = 0;
    let pres3 = 0;
    let pres2 = 0;
    let pres1 = 0;

    for (let i = 0; i < reviews.length; i++) {
      total += reviews[i].rating;

      if (reviews[i].rating === 5) pres5 += 1;
      if (reviews[i].rating === 4) pres4 += 1;
      if (reviews[i].rating === 3) pres3 += 1;
      if (reviews[i].rating === 2) pres2 += 1;
      if (reviews[i].rating === 1) pres1 += 1;
    }

    const avgTotal = total / reviews.length;
    const avg1 = (pres1 / reviews.length) * 100;
    const avg2 = (pres2 / reviews.length) * 100;
    const avg3 = (pres3 / reviews.length) * 100;
    const avg4 = (pres4 / reviews.length) * 100;
    const avg5 = (pres5 / reviews.length) * 100;

    return { avgTotal, avg1, avg2, avg3, avg4, avg5 };
  };

  return (
    <Stack gap={3}>
      <Text fontWeight={"semibold"} fontSize={24}>
        <FormattedMessage id="customerRatingsTitle" />
      </Text>
      <HStack gap={4} color={"#5b5b5b"}>
        <ItemEvaluationProvider
          starsNumber={getStatistics().avgTotal}
          fontSize={17}
        />
        <Text>{`${getStatistics().avgTotal} ${messages[locale].outOf} 5`}</Text>
        <Text
          fontSize={12}
        >{`${reviews.length} ${messages[locale].globalReviews}`}</Text>
      </HStack>
      <Progress star={"5"} rating={getStatistics().avg5} />
      <Progress star={"4"} rating={getStatistics().avg4} />
      <Progress star={"3"} rating={getStatistics().avg3} />
      <Progress star={"2"} rating={getStatistics().avg2} />
      <Progress star={"1"} rating={getStatistics().avg1} />
      <Text fontWeight={"semibold"} fontSize={23}>
        <FormattedMessage id="customerRatingFooterHead" />
      </Text>
      <Text fontWeight={"semibold"} fontSize={15} color={"#5b5b5b"}>
        <FormattedMessage id="customerRatingFooterTail" />
      </Text>
      <AddNewReview product={product} />
    </Stack>
  );
};

export default ClientsRatings;

const Progress = ({ star, rating }: { star: string; rating: number }) => {
  return (
    <HStack color={"#999999"}>
      <HStack gap={0}>
        <Text>{star}</Text>
        <Stack color={"#ffc107"} fontSize={17}>
          <MdOutlineStar />
        </Stack>
      </HStack>
      <RatingBar rating={rating} />
      <Text fontSize={15}>{`${rating.toString()}%`}</Text>
    </HStack>
  );
};
