import { Box, HStack } from "@chakra-ui/react";
import { bodyPadding } from "../../assets/global";
import CopyRightsAndSocial from "./CopyRightsAndSocial";
import ForCustomers from "./ForCustomers";
import MeetWithUs from "./MeetWithUs";

const Footer = () => {
  return (
    <Box px={bodyPadding} py={39} color={"white"} backgroundColor={"#01114d"}>
      <HStack gap={20} pb={6}>
        <MeetWithUs />
        <ForCustomers />
      </HStack>
      <hr />
      <CopyRightsAndSocial />
    </Box>
  );
};

export default Footer;
