import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { CiViewTimeline } from "react-icons/ci";

import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { OrdersTableProps, ProductsOrders } from "../../data/types";

const OrdersTable = ({ orders }: { orders: OrdersTableProps[] }) => {
  const [ordersProds, setOrdersProds] = useState<any>([]);

  useEffect(() => {
    const updatedOrdersProds: any = [];

    orders.forEach((order) => {
      order.orderItems.forEach((prod) => {
        const orderData = {
          product: prod.product,
          orderId: order.orderId,
          date: order.dateTime,
          price: prod.price,
          qty: prod.quantity,
          status: order.status,
          address: order.address,
        };
        updatedOrdersProds.push(orderData);
      });
    });

    setOrdersProds(updatedOrdersProds);
  }, [orders]);

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr backgroundColor={"#eeeeee"}>
            <Th>
              <FormattedMessage id="products" />
            </Th>
            <Th>
              <FormattedMessage id="orderId" />
            </Th>
            <Th>
              <FormattedMessage id="date" />
            </Th>
            <Th>
              <FormattedMessage id="qty" />
            </Th>
            <Th>
              <FormattedMessage id="status" />
            </Th>
            <Th>
              <FormattedMessage id="price" />
            </Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {ordersProds.map((prod: ProductsOrders, index: number) => (
            <Tr key={index} fontWeight={"semibold"}>
              <Td>{prod.product}</Td>
              <Td>{prod.orderId}</Td>
              <Td>{prod.date}</Td>
              <Td>{prod.qty}</Td>
              <Td>{prod.status}</Td>
              <Td>{prod.price}</Td>
              <Td>
                <CiViewTimeline />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default OrdersTable;
