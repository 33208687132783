import { Flex, Heading, Stack, Text } from "@chakra-ui/react";
import MyAccountLayout from "../components/MyAccountLayout";
import CartViewer from "../components/NavBar/CartDrawer/CartViewer";
import AddressAndPayment from "../components/ShoppingCart/AddressAndPayment";

const ShoppingCart = () => {
  return (
    <MyAccountLayout>
      <Stack gap={5} w={"100%"}>
        <CartViewer />
        <AddressAndPayment />
      </Stack>
    </MyAccountLayout>
  );
};

export default ShoppingCart;
