import { Box, Card, Flex, Stack, Text } from "@chakra-ui/react";
import { AddressProps } from "../../data/types";
import ModifyAddressModal from "./ModifyAddressModal";
import DeleteAddress from "./DeleteAddress";

const AddressCard = ({ data }: { data: AddressProps }) => {
  return (
    <Card overflow="hidden" variant="outline">
      <Stack px={6} py={4} fontWeight={"semibold"} fontSize={"medium"} gap={3}>
        <Text>{data.firstName}</Text>
        <Box color={"#B0B0B0"}>
          <Text>{data.lastName}</Text>
          <Text>{data.firstAddress}</Text>
          <Text>{data.secondAddress}</Text>
          <Text>{data.phone}</Text>
        </Box>
        <Flex justifyContent={"space-between"}>
          <ModifyAddressModal initialValues={data} />
          <DeleteAddress id={data.id} />
        </Flex>
      </Stack>
    </Card>
  );
};

export default AddressCard;
