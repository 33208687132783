import {
  HStack,
  Text,
  Link,
  Image,
  Flex,
  Input,
  Button,
} from "@chakra-ui/react";
import AccountPagesHeader from "../components/Account/AccountPagesHeader";
import { FormattedMessage } from "react-intl";
import { bodyPadding } from "../assets/global";
import { FormEvent, useContext } from "react";
import { LangContext } from "../contexts/LanguageProviderContext";
import messages from "../i18n/messages";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const { locale } = useContext(LangContext);
  const nav = useNavigate();
  const handleFormAction = async (e: FormEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    const email = formData.get("email") as string;
  };

  return (
    <>
      <AccountPagesHeader>
        <HStack fontWeight={"semibold"}>
          <Text>
            <FormattedMessage id="dontHaveAcc" />
          </Text>
          <Link onClick={() => nav(`/signup`)}>
            <FormattedMessage id="registerLink" />
          </Link>
        </HStack>
      </AccountPagesHeader>
      <Flex
        px={bodyPadding}
        justifyContent={"space-evenly"}
        py={"5.6rem"}
        wrap={"wrap"}
      >
        <Image alt={""} src="/images/account/fp.svg" boxSize={250} />
        <Flex flexDir={"column"} gap={8} maxW={450}>
          <Flex flexDir={"column"}>
            <Text fontSize={30} fontWeight={"semibold"} color={"rgb(1,17,77)"}>
              <FormattedMessage id="resetPasswordPage" />
            </Text>
            <Text fontSize={14} fontWeight={"semibold"} color={"#818181"}>
              <FormattedMessage id="resetPasswordDescription" />
            </Text>
          </Flex>
          <form onSubmit={handleFormAction}>
            <Input
              name="email"
              type="email"
              placeholder={messages[locale].email}
              size="md"
              borderColor={"rgb(1,17,77)"}
              _hover={{ borderColor: "rgb(1,17,77)" }}
              borderWidth={1}
              mb={3}
            />
            <Button
              type="submit"
              backgroundColor={"rgb(1,17,77)"}
              color={"white"}
              w={"100%"}
              _hover={{
                bg: "white",
                borderColor: "rgb(1,17,77)",
                border: "1px",
                color: "rgb(1,17,77)",
              }}
              mb={3}
            >
              <FormattedMessage id="resetPasswordButton" />
            </Button>
            <Link onClick={() => nav(`/signup`)}>
              <Button color={"rgb(1,17,77)"} w={"100%"}>
                <FormattedMessage id="goBack" />
              </Button>
            </Link>
          </form>
        </Flex>
      </Flex>
    </>
  );
};

export default ResetPassword;
