import { Flex, HStack, Image, Link, Text, useToast } from "@chakra-ui/react";
import { FormEvent, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { bodyPadding } from "../assets/global";
import AccountPagesHeader from "../components/Account/AccountPagesHeader";
import { AuthContext } from "../contexts/AuthProviderContext";
import { LangContext } from "../contexts/LanguageProviderContext";
import messages from "../i18n/messages";
import SignInForm from "../components/Account/SignInForm";
import { useNavigate } from "react-router-dom";

const SignIn = () => {
  const { signIn } = useContext(AuthContext);
  const { locale } = useContext(LangContext);
  const toast = useToast();
  const nav = useNavigate();

  const handleFormAction = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data = await signIn(formData);
    if (data?.error) {
      toast({
        description: messages[locale].signInError,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    } else {
      nav("/");
    }
  };

  return (
    <>
      <AccountPagesHeader>
        <HStack fontWeight={"semibold"}>
          <Text>
            <FormattedMessage id="dontHaveAcc" />
          </Text>
          <Link onClick={() => nav(`/signup`)}>
            <FormattedMessage id="registerLink" />
          </Link>
        </HStack>
      </AccountPagesHeader>
      <Flex
        px={bodyPadding}
        justifyContent={"space-evenly"}
        py={"5.6rem"}
        wrap={"wrap"}
      >
        <Image alt={""} src="/images/account/signin.svg" boxSize={250} />
        <Flex flexDir={"column"} gap={8}>
          <Flex flexDir={"column"}>
            <Text fontSize={30} fontWeight={"semibold"} color={"rgb(1,17,77)"}>
              <FormattedMessage id="signInPage" />
            </Text>
            <Text fontSize={14} fontWeight={"semibold"} color={"#818181"}>
              <FormattedMessage id="signInDesc" />
            </Text>
          </Flex>
          <SignInForm handleSubmit={handleFormAction} />
        </Flex>
      </Flex>
    </>
  );
};

export default SignIn;
