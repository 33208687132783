import { RadioGroup, Stack, Radio, Flex } from "@chakra-ui/react";
import { useContext } from "react";
import { MdOutlineStarBorder, MdOutlineStar } from "react-icons/md";
import { ParamsContext } from "../../contexts/ParamsProviderContext";
import { SearchParams } from "../../data/types";

const Evaluation = () => {
  const { searchParams, setSearchParams } = useContext(ParamsContext);

  const handleSelected = (value: string) => {
    setSearchParams((prev: SearchParams) => ({
      ...prev,
      rating: parseInt(value),
    }));
  };

  return (
    <RadioGroup
      defaultValue={searchParams.evaluation}
      onChange={handleSelected}
    >
      <Stack color={"#ffc107"}>
        <Radio value="5">
          <Flex>
            <MdOutlineStar />
            <MdOutlineStar />
            <MdOutlineStar />
            <MdOutlineStar />
            <MdOutlineStar />
          </Flex>
        </Radio>
        <Radio value="4">
          <Flex>
            <MdOutlineStar />
            <MdOutlineStar />
            <MdOutlineStar />
            <MdOutlineStar />
            <MdOutlineStarBorder />
          </Flex>
        </Radio>
        <Radio value="3">
          <Flex>
            <MdOutlineStar />
            <MdOutlineStar />
            <MdOutlineStar />
            <MdOutlineStarBorder />
            <MdOutlineStarBorder />
          </Flex>
        </Radio>
        <Radio value="2">
          <Flex>
            <MdOutlineStar />
            <MdOutlineStar />
            <MdOutlineStarBorder />
            <MdOutlineStarBorder />
            <MdOutlineStarBorder />
          </Flex>
        </Radio>
        <Radio value="1">
          <Flex>
            <MdOutlineStar />
            <MdOutlineStarBorder />
            <MdOutlineStarBorder />
            <MdOutlineStarBorder />
            <MdOutlineStarBorder />
          </Flex>
        </Radio>
      </Stack>
    </RadioGroup>
  );
};

export default Evaluation;
