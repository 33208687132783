import { HStack, Link, Text } from "@chakra-ui/react";
import { CiSettings } from "react-icons/ci";
import { FaShoppingBag, FaShoppingCart } from "react-icons/fa";
import { FaRegBell } from "react-icons/fa6";
import { MdLocationPin } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";

const SideLink = ({
  icon,
  text,
  href,
}: {
  icon: string;
  text: string;
  href: string;
}) => {
  const location = useLocation();
  const nav = useNavigate();
  const Icon =
    icon === "CiSettings"
      ? CiSettings
      : icon === "FaShoppingCart"
      ? FaShoppingCart
      : icon === "MdLocationPin"
      ? MdLocationPin
      : icon === "FaRegBell"
      ? FaRegBell
      : FaShoppingBag;
  return (
    <Link onClick={() => nav(`/${href}`)}>
      <HStack
        fontSize={15}
        fontWeight={location.pathname.includes(href) ? "semibold" : "inherit"}
        px={4}
        py={2}
        borderRadius={8}
        w={{ base: 180, lg: 250 }}
        h={"fit-content"}
        gap={3}
        backgroundColor={location.pathname.includes(href) ? "black" : "inherit"}
        color={location.pathname.includes(href) ? "white" : "inherit"}
        _hover={{ bg: location.pathname.includes(href) ? "black" : "#eeeeee" }}
      >
        <Text>
          <Icon />
        </Text>
        <Text>{text}</Text>
      </HStack>
    </Link>
  );
};

export default SideLink;
