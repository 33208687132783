import { Button, useToast } from "@chakra-ui/react";
import { FormEvent, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthProviderContext";
import { postRequest } from "../../utils/db";
import messages from "../../i18n/messages";
import { LangContext } from "../../contexts/LanguageProviderContext";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

const DeleteAccount = () => {
  const toast = useToast();
  const { signOut, getSession } = useContext(AuthContext);
  const { locale } = useContext(LangContext);
  const [session, setSession] = useState<any>();
  const nav = useNavigate();

  const fetchSession = async () => {
    const data = await getSession();
    if (data) setSession(data);
  };

  useEffect(() => {
    fetchSession();
  }, []);

  const deleteAccountAction = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await postRequest(
        `/api/clients/delete/${session?.data.id}`,
        {},
        session?.data.access_token
      );
      await signOut();
      toast({
        description: messages[locale].deleteAccountSuccess,
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      nav("/");
    } catch (error) {
      toast({
        description: messages[locale].deleteAccountError,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return (
    <form onSubmit={deleteAccountAction}>
      <Button
        colorScheme="red"
        w={"fit-content"}
        variant="outline"
        type="submit"
      >
        <FormattedMessage id="deleteAccAction" />
      </Button>
    </form>
  );
};

export default DeleteAccount;
