import { Box } from "@chakra-ui/react";
import { bodyPadding } from "../assets/global";
import HelpCenterIntro from "../components/CentreAssistance/HelpCenterIntro";
import OrdersFollowUp from "../components/CentreAssistance/OrdersFollowUp";
import ServicesCards from "../components/CentreAssistance/ServicesCards";

const CentreAssistance = () => {
  return (
    <Box px={bodyPadding}>
      <HelpCenterIntro />
      <ServicesCards />
      <OrdersFollowUp />
    </Box>
  );
};

export default CentreAssistance;
