import { Input, HStack, Button, Text, Link } from "@chakra-ui/react";
import { FormEvent, useContext } from "react";
import { FormattedMessage } from "react-intl";
import messages from "../../i18n/messages";
import { LangContext } from "../../contexts/LanguageProviderContext";
import InputPassword from "../common/form/InputPassword";
import { useNavigate } from "react-router-dom";

const SignInForm = ({
  handleSubmit,
}: {
  handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
}) => {
  const { locale } = useContext(LangContext);
  const nav = useNavigate();

  return (
    <form onSubmit={handleSubmit}>
      <Input
        name="email"
        placeholder={messages[locale].email}
        size="md"
        borderColor={"rgb(1,17,77)"}
        _hover={{ borderColor: "rgb(1,17,77)" }}
        borderWidth={1}
        mb={3}
        // value={"administrateur@optimgov.com"}
      />
      <InputPassword isRequired />
      <HStack fontWeight={"semibold"}>
        <Text color={"#818181"}>
          <FormattedMessage id="forgotPassword" />
        </Text>
        <Link onClick={() => nav(`/reset-password`)} color={"rgb(1,17,77)"}>
          <FormattedMessage id="forgotPasswordLink" />
        </Link>
      </HStack>
      <Button
        type="submit"
        backgroundColor={"rgb(1,17,77)"}
        color={"white"}
        my={3}
        w={"100%"}
        _hover={{
          bg: "white",
          borderColor: "rgb(1,17,77)",
          border: "1px",
          color: "rgb(1,17,77)",
        }}
      >
        <FormattedMessage id="signInButton" />
      </Button>
      <HStack fontWeight={"semibold"}>
        <Text color={"#818181"}>
          <FormattedMessage id="dontHaveAcc" />
        </Text>
        <Link onClick={() => nav(`/signup`)} color={"rgb(1,17,77)"}>
          <FormattedMessage id="registerLink" />
        </Link>
      </HStack>
    </form>
  );
};

export default SignInForm;
