import { RouterProvider } from "react-router-dom";
import "./App.css";
import ChakraUIProvider from "./components/ChakraUIProvider";
import AuthProviderContext from "./contexts/AuthProviderContext";
import { default as LanguageProviderContext } from "./contexts/LanguageProviderContext";
import ShoppingCartProviderContext from "./contexts/ShoppingCartProviderContext";
import router from "./pages/router";
import WishlistProviderContext from "./contexts/WishlistProviderContext";
import ParamsProviderContext from "./contexts/ParamsProviderContext";

function App() {
  return (
    <LanguageProviderContext>
      <AuthProviderContext>
        <ShoppingCartProviderContext>
          <WishlistProviderContext>
            <ParamsProviderContext>
              <ChakraUIProvider>
                <RouterProvider router={router} />
              </ChakraUIProvider>
            </ParamsProviderContext>
          </WishlistProviderContext>
        </ShoppingCartProviderContext>
      </AuthProviderContext>
    </LanguageProviderContext>
  );
}

export default App;
