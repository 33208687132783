import { Flex, Spinner } from "@chakra-ui/react";
import React from "react";

const ABRSpinner = () => {
  return (
    <Flex w={"full"} py={"200"} justifyContent={"center"} alignItems={"center"}>
      <Spinner
        thickness="10px"
        speed="0.65s"
        emptyColor="gray.200"
        color="#01114d"
        w={"200px"}
        h={"200px"}
      />
    </Flex>
  );
};

export default ABRSpinner;
