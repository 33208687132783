import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import { ItemProps } from "../../../data/types";
import { FormattedMessage } from "react-intl";
import DescriptionTab from "./DescriptionTab";
import InformationTab from "./InformationTab";
import ReviewsTab from "./ReviewsTab";

const TabsContainer = ({ product }: { product: ItemProps }) => {
  return (
    <Tabs colorScheme="yellow" py={"60px"}>
      <TabList>
        <Tab fontSize={13} fontWeight={"semibold"}>
          <FormattedMessage id="productDetailsTabTitle" />
        </Tab>
        <Tab fontSize={13} fontWeight={"semibold"}>
          <FormattedMessage id="informationTabTitle" />
        </Tab>
        <Tab fontSize={13} fontWeight={"semibold"}>
          <FormattedMessage id="reviewsTabTitle" />
        </Tab>
      </TabList>

      <TabPanels minH={320}>
        <TabPanel>
          <DescriptionTab product={product!} />
        </TabPanel>
        <TabPanel>
          <InformationTab product={product!} />
        </TabPanel>
        <TabPanel>
          <ReviewsTab product={product!} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default TabsContainer;
