import { Stack, Text } from "@chakra-ui/react";
import { ItemProps } from "../../../data/types";
import { FormattedMessage } from "react-intl";

const DescriptionTab = ({ product }: { product: ItemProps }) => {
  return (
    <Stack>
      <Text fontWeight={"semibold"} fontSize={22} py={2}>
        <FormattedMessage id="descriptionTab" />
      </Text>
      <Text fontWeight={"semibold"} fontSize={18}>
        <FormattedMessage id="subTitle" />
      </Text>
      <Text color={"#5b5b5b"}>{product?.descriptionProduct}</Text>
      <Text fontWeight={"semibold"} fontSize={18} pt={2}>
        <FormattedMessage id="unitTitle" />
      </Text>
      <Text color={"#5b5b5b"}>{product?.unites}</Text>
      {/* <Text fontWeight={"semibold"} fontSize={18} pt={2}>
          <FormattedMessage id="sellerTitle" />
          </Text>
          <Text color={"#5b5b5b"}>{`{sellerName}`}</Text> */}
    </Stack>
  );
};

export default DescriptionTab;
