import {
  HStack,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { TbCategory } from "react-icons/tb";
import { FormattedMessage } from "react-intl";
import {
  Category,
  Menu1CategoresProps,
  SearchParams,
} from "../../../data/types";
import { ParamsContext } from "../../../contexts/ParamsProviderContext";
import { useNavigate } from "react-router-dom";

const Menu1Categores: React.FC<Menu1CategoresProps> = ({
  menuLocation,
  closeDrawer,
  categories,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showLinks, setShowLinks] = useState(false);
  const { setSearchParams } = useContext(ParamsContext);
  const nav = useNavigate();
  return (
    <>
      <Menu isOpen={isOpen}>
        <MenuButton
          px={6}
          py={3}
          transition="all 0.2s"
          borderRadius="md"
          color={"#09104c"}
          backgroundColor={"#eac102"}
          onMouseEnter={() => {
            if (!menuLocation) onOpen();
          }}
          onMouseLeave={onClose}
          onClick={() => {
            if (menuLocation) setShowLinks(!showLinks);
          }}
          w={menuLocation === "side" ? "100%" : "inherit"}
        >
          <HStack justifyContent={"center"}>
            <TbCategory />
            <Text fontWeight={"bolder"}>
              <FormattedMessage id="menuCategores" />
            </Text>
          </HStack>
        </MenuButton>
        <MenuList
          color={"black"}
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
          zIndex={1000}
        >
          <Link
            onClick={() => {
              setSearchParams((prev: SearchParams) => ({
                ...prev,
                catName: "",
                catId: null,
              }));
              nav("/shopping-items");
              onClose();
            }}
          >
            <MenuItem
              onClick={() =>
                setSearchParams({
                  catId: null,
                  min_price: 0,
                  max_price: 1500,
                  name: "",
                  display: 10,
                  evaluation: null,
                  orderBy: "",
                  page: 1,
                })
              }
            >
              <FormattedMessage id="allCategories" />
            </MenuItem>
          </Link>
          {categories.map(
            (category: Category, index: number) =>
              category.parentCategoryId === 0 && (
                <Link
                  key={index}
                  onClick={() => {
                    setSearchParams((prev: SearchParams) => ({
                      ...prev,
                      catName: category.name,
                      catId: category.id,
                    }));
                    nav("/shopping-items");
                    onClose();
                  }}
                >
                  <MenuItem>{category.name}</MenuItem>
                </Link>
              )
          )}
        </MenuList>
      </Menu>
      {showLinks && (
        <Stack
          px={3}
          py={2}
          color={"#000000"}
          w={"100%"}
          borderRadius={8}
          borderWidth={1}
          borderColor={"#bcbcbc"}
        >
          {categories.map(
            (category: Category, index: number) =>
              category.parentCategoryId === 0 && (
                <Link
                  py={1}
                  px={2}
                  borderRadius={5}
                  key={index}
                  onClick={() => {
                    setSearchParams((prev: SearchParams) => ({
                      ...prev,
                      catName: category.name,
                      catId: category.id,
                    }));
                    nav("/shopping-items");
                    if (closeDrawer) closeDrawer();
                  }}
                  _hover={{ bg: "#eeeeee" }}
                >
                  {category.name}
                </Link>
              )
          )}
        </Stack>
      )}
    </>
  );
};

export default Menu1Categores;
