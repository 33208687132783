import { FormEvent, ReactNode, useContext, useEffect, useState } from "react";
import { CartContext } from "../../contexts/ShoppingCartProviderContext";
import { AuthContext } from "../../contexts/AuthProviderContext";
import { useToast } from "@chakra-ui/react";
import { executeCart } from "./action";
import { useNavigate } from "react-router-dom";

const ShippingOptions = ({ children }: { children: ReactNode }) => {
  const { cartItems, setCartItems } = useContext(CartContext);
  const { getSession } = useContext(AuthContext);
  const [session, setSession] = useState<any>();
  const nav = useNavigate();

  const fetchSession = async () => {
    const data = await getSession();
    if (data) setSession(data);
  };

  useEffect(() => {
    fetchSession();
  }, []);

  const toast = useToast();

  const handleCart = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data = await executeCart(formData, cartItems, session);
    console.log("data from create orders", data);
    if (data?.error) {
      toast({
        description: `Error making order`,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    } else {
      setCartItems([]);
      toast({
        description: `Your order has been created`,
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      nav("/");
    }
  };

  return cartItems.length > 0 ? (
    <form onSubmit={handleCart}>{children}</form>
  ) : (
    <></>
  );
};

export default ShippingOptions;
