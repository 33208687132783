import { useContext } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import { LangContext } from "../contexts/LanguageProviderContext";
import { I18nProvider } from "../i18n";

const Layout = () => {
  const { locale } = useContext(LangContext);

  return (
    <I18nProvider locale={locale}>
      <NavBar />
      <Outlet />
      <Footer />
    </I18nProvider>
  );
};

export default Layout;
